import req from "../../utils/req";
// params  query传参
// data    body传参
export default {
  //申请企业认证
  applyApprove(param) {
    return req.get("/app/apply_qiye", param)
  },
  //登录
  login(param) {
    return req.post("/front/login", param)
  },
  // 注册
  register(param) {
    return req.post('/front/register', param)
  },
  // // 上传图片
  uploadFile(param) {
    return req.post("/front/upload_file", param)
  },
  // 获取用户数据
  getUser(param) {
    return req.get("/app/get_user_info", param)
  },
  // 页面初始化集合
  getSelect(param) {
    return req.post("/front/get_init_info", param)
  },
  // 获取省市区
  getArea(param) {
    return req.get("/front/get_area_list", param)
  },
  // 修改昵称
  editData(param) {
    return req.post("/app/edit_nickname", param)
  },
  // 退出登录/app/logout
  logout(param) {
    return req.post("/app/logout", param)
  },
  // 修改密码/app/edit_pwd
  changePwd(param) {
    return req.post("/app/edit_pwd", param)
  },
  // 发送验证码/front/sms_send
  sendCode(param) {
    return req.get('/front/sms_send', param)
  },
  // 修改手机号/app/edit_phone
  changeTel(param) {
    return req.post('/app/edit_phone', param)
  },
  // 发布仓库求租/app/release_seek_rent
  askForRent(param) {
    return req.post('/app/release_seek_rent', param)
  },
  // 发布仓库出租/app/release_hire
  rentOut(param) {
    return req.post('/app/release_hire', param)
  },
  // 出租列表
  rentList(param) {
    return req.post('/app/my_release', param)
  },
  // 列表详情
  rentDetail(param) {
    return req.post("/app/my_release_detail", param)
  },
  // 获取系统配置
  getConfig(param) {
    return req.get("/front/get_system_info", param)
  },
  //	发布招聘
  release_recruit(param) {
    return req.post("/app/release_recruit", param)
  },
  //	获取岗位的分类信息
  get_recruit_class(param) {
    return req.get("/front/get_recruit_class", param)
  },
  check_group_user(param) {
    return req.post("/app/check_group_user", param)
  },
  // 获取仓库详情数据
  warehouse_list(param) {
    return req.post("/app/warehouseGroupList", param)
  }

  // baseUrl(){
  // 	let url = baseUrl
  // 	return url
  // },
  // // 热更新
  // // /api/index/gerUserVersion
  // gerUserVersion(param){
  // 	return req.get("/api/index/gerUserVersion", param)
  // }


}