import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    meta: {
      auth: false
    },
    component: (resolve) => require(['../views/main.vue'], resolve),
    children: [{
        path: '/',
        meta: {
          auth: false
        },
        component: (resolve) => require(['../views/home.vue'], resolve),
      },
      {
        path: '/home',
        meta: {
          auth: false
        },
        component: (resolve) => require(['../views/home.vue'], resolve),
      }
    ]
  },
  {
    path: '/lingxi-warehouse',
    meta: {
      auth: false,
      title: "_仓库详情"
    },
    component: (resolve) => require(['../views/lingxi.vue'], resolve),
  },
  {
    path: '/recruitment',
    meta: {
      auth: false,
      title: "人才招聘_京科仓链"
    },
    component: (resolve) => require(['../views/recruitment.vue'], resolve),
  },
  {
    path: '/jinrong',
    meta: {
      auth: false,
      title: "金融产品_京科仓链"
    },
    component: (resolve) => require(['../views/jinrong.vue'], resolve),
  },
  {
    path: '/rentDetail',
    meta: {
      auth: false,
      title: "京科仓链"
    },
    component: (resolve) => require(['../views/rentDetail.vue'], resolve),
  },
  {
    path: '/askforrent',
    meta: {
      auth: false,
      title: "仓库求租_京科仓链"
    },
    component: (resolve) => require(['../views/askforrent.vue'], resolve),
  },
  {
    path: '/detail',
    meta: {
      auth: false,
      title: "_地图找库_京科仓链"
    },
    component: (resolve) => require(['../views/detail.vue'], resolve),
  },
  {
    path: '/xieyi',
    meta: {
      auth: false
    },
    component: (resolve) => require(['../views/xieyi.vue'], resolve),
  },
  {
    path: '/map',
    name: 'Map',
    meta: {
      auth: false,
      title: "地图找库_京科仓链"
    },
    component: (resolve) => require(['../views/map'], resolve),
  },
  {
    path: '/law',
    meta: {
      auth: false
    },
    component: (resolve) => require(['../views/law.vue'], resolve),
  },
  {
    path: '/personal',
    meta: {
      auth: false,
    },
    component: (resolve) => require(['../views/personal.vue'], resolve),
    children: [{
        path: '/',
        meta: {
          auth: false,
          title: "个人中心_京科仓链",
          keepAlive: true // 添加keep-alive属性
        },

        component: (resolve) => require(['../views/personal/account.vue'], resolve),
      },
      // {
      // 	path: '/account',
      // 	meta: {
      // 		auth: false
      // 	},
      // 	component: (resolve) => require(['../views/personal/account.vue'], resolve),
      // },
      {
        path: '/rentList',
        meta: {
          auth: false,
          keepAlive: true // 添加keep-alive属性
        },
        component: (resolve) => require(['../views/personal/rentList.vue'], resolve),
      },
      {
        path: '/recList',
        meta: {
          auth: false,
          keepAlive: true // 添加keep-alive属性
        },
        component: (resolve) => require(['../views/personal/recList.vue'], resolve),
      },
      {
        path: '/askList',
        meta: {
          auth: false,
          keepAlive: true // 添加keep-alive属性
        },
        component: (resolve) => require(['../views/personal/askList.vue'], resolve),
      },
    ]
  },
  {
    path: '/renzheng',
    meta: {
      auth: false
    },
    component: (resolve) => require(['../views/personal/renzheng.vue'], resolve),
  },
  {
    path: '/allMap',
    name: 'allMap',
    meta: {
      auth: false,
      title: "仓库预览_京科仓链"
    },
    component: (resolve) => require(['../views/allMap/index.vue'], resolve),
  },


]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 期望滚动到哪个位置
    return {
      x: 0,
      y: 0
    }
  },
})


router.beforeEach((to, from, next) => {
  const {
    meta: {
      title = "京科仓链"
    }
  } = to;
  document.title = title;
  next();
})

export default router