import request from "@/utils/request";
// params  query传参
// data    body传参
let house;
export default house = {
  shareInfo: function () {
    return request({
      url: `/system/wechat/shareInfo`,
      method: "get",
    });
  },
  warehouseDetailById: function (warehouseId) {
    return request({
      url: `/system/front/warehouseDetailById/${warehouseId}`,
      method: "get",
    });
  },
  warehouseRecommendList: function (warehouseId) {
    // 
    return request({
      url: `/system/front/warehouseRecommendList/${warehouseId}`,
      method: "get",
    });
  },
  commonCategoryList: function () {
    return request({
      url: `/system/front/commonCategoryList`,
      method: "get",
    });
  },
  mapList: function (params) {
    // 首页搜索
    return request({
      url: `/system/front/mapList`,
      method: "get",
      params:params
    });
  },
  warehouseBannerList: function (data) {
    return request({
      url: `/system/front/warehouseBannerList?warehouseId=${data}`,
      method: "get",
    });
  },
  warehousePageInfo: function (data) {
    return request({
      url: `/system/front/warehousePageInfo`,
      method: "post",
      data:data
    });
  },
  mapAreaAndWarehouseInfos: function (data) {
    return request({
      url: `/system/front/mapAreaAndWarehouseInfos`,
      method: "post",
      data:data
    });
  },

  /*
   * TODO 未完成 
   * 调用微信2个接口，同时生成签名和相关信息返回给前端
   */
};
