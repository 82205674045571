<template>
  <div class="footer">
    <div class="address">
      <div class="w12 flex">
        <div class="l">
          办公地址：北京市丰台区凤凰嘴街5号院2号楼鼎兴大厦A座8楼
        </div>
        <div class="r">
          <span class="primary">400-673-7868 转 3</span>
          <span class="ghost"> ( 8:30-17:00 )</span>
        </div>
      </div>
    </div>

    <div class="info">
      <div class="w12">
        <div class="flex">
          <div class="">
            <img
              class="logo"
              src="../../assets/img/main/logo-hover.png"
              alt=""
            />
            <div class="text flex">
              <p class="p2" @click="jump('https://www.cmstjd.com/')">中储京科</p>
              <p class="p2" @click="jump('https://www.lingxidata.cn/')">灵犀超链</p>
              <div class="line"></div>
              <p class="p4" @click="jumpLaw('法律声明')">法律声明</p>
              <p class="p3" @click="jumpLaw('隐私声明')">隐私声明</p>
              <p class="p5" @click="jumpLaw('使用条款')">使用条款</p>
            </div>
          </div>
          <el-popover placement="top" trigger="hover" width="100">
            <img class="ercode" src="../../assets/img/main/ercode-hdb.jpg" />
            <div class="xcx" slot="reference">
              <div class="xcx-img"></div>
              <div class="xcx-t">小程序</div>
            </div>
          </el-popover>
          <el-popover placement="top" width="100" trigger="hover">
            <img class="ercode" src="../../assets/img/main/ercode-wx.jpg" />
            <div class="wx" slot="reference">
              <div class="wx-img"></div>
              <div class="wx-t">微信公众号</div>
            </div>
          </el-popover>
        </div>
      </div>
    </div>

    <div class="b">
      <p>
        2021-2022 中储京科供应链管理有限公司Copyright
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">鲁ICP备19060599号-2</a
        >
      </p>
      <p class="gh">
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=37021002001052" rel="noreferrer" target="_blank">鲁公网安备37021002001052</a>
      </p>
      <p>
        中国（山东）自由贸易试验区青岛片区前湾保税港区北京路45号综合办公楼106室（A）
      </p>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    jump(url) {
      window.open(url);
    },
    jumpLaw(data) {
      this.$router.push({ path: "/law", query: { selectName: data } });
    },
  },
};
</script>
