<template>
  <nav class="map-nav">
    <div class="nav flex">
      <router-link to="/"><div class="nav-logo"></div></router-link>
      <!-- <div class="nav-line"></div> -->

      <el-cascader
        class="map-nav-input"
        style="margin-left: 170px"
        clearable
        size="large"
        v-model="selectedOptions"
        :props="navInputProps"
        placeholder="请选择仓库所在区域"
      >
      </el-cascader>

      <el-select
        v-model="categoryId"
        placeholder="请选择品类"
        class="map-nav-input"
        clearable
      >
        <el-option
          v-for="item in categoryList"
          :key="item.categoryId"
          :label="item.categoryName"
          :value="item.categoryId"
        >
        </el-option>
      </el-select>

      <el-input
        placeholder="请输入仓库关键词"
        v-model="warehouseName"
        class="map-nav-input"
      ></el-input>
      <el-button class="nav-btn" @click="handleGetMapList" type="primary"
        >查询<i class="el-icon-search"></i
      ></el-button>
    </div>
  </nav>
</template>
<script>
import { regionData } from "element-china-area-data";
import house from "../../api/housedetail";
export default {
  name: "map-nav",
  data() {
    return {
      options: regionData,
      optionsTwo: [],

      selectedOptions: [],
      categoryList: [],
      categoryId: "",
      warehouseName: "",
      navInputProps: {
        lazy: true,
        value: "id",
        label: "name",
        lazyLoad: (node, resolve) => {
          // console.log("node", node);
          const { level } = node;

          house
            .mapList({
              showAll: 0,
              pid: node.level === 0 ? null : node.data.id,
            })
            .then((res) => {
              res.data.forEach((it) => {
                it.leaf = level >= 2;
              });
              resolve(res.data);
            });
        },
      },
    };
  },
  methods: {
    handleGetMapList() {
      const [provinceId = '', cityId = '', countyId = ''] = this.selectedOptions;
      this.$emit("update-map", {
        pageNum: 1,
        pageSize: 10,
        province: provinceId.toString(),
        city: cityId.toString(),
        county: countyId.toString(),
        warehouseName: this.warehouseName,
        categoryIds: [this.categoryId].filter(item => item)
      });
      // return
      // let data = {
      //   warehouseName: this.warehouseName,
      //   province: this.selectedOptions[0] ? this.selectedOptions[0] : "",
      //   city: this.selectedOptions[1] ? this.selectedOptions[1] : "",
      //   county: this.selectedOptions[2] ? this.selectedOptions[2] : "",
      //   categoryIds: [this.categoryName].filter((el) => el),
      //   areaIds: this.selectedOptions,
      // };
      // house.mapAreaAndWarehouseInfos(data).then((res) => {
      //   this.$emit("update-map", res.data);
      // });
    },
  },
  mounted() {
    const { name = '', provinceId, cityId, countyId } = this.$route.query;
    this.warehouseName = name;
    if (countyId) {
      this.selectedOptions = [
        Number(provinceId),
        Number(cityId),
        Number(countyId),
      ];
    }
    house.commonCategoryList().then((res) => {
      this.categoryList = res.data;
    });
  },
};
</script>
<style scoped>
  .map-nav {
    display: flex;
    align-items: center;
  }
  .map-nav .nav {
    margin: 0 180px;
  }
  .map-nav-input {
    margin-left: 15px;
  }
  .nav-btn {
    margin-left: 15px;
  }
</style>
