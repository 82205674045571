import Vue from 'vue'
import App from './App.vue'
import AmapVue from '@amap/amap-vue';
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles/index.less'
import 'animate.css';
// 插件
import Utils from './utils/utils'
import plugins from './components/plugin'
import BaiduMap from 'vue-baidu-map'
// 使用自定义插件及组件库，挂载到Vue实例上
Vue.use(Utils)
Vue.use(plugins)
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  // 当前为测试key（不具备部分功能或受限制），请自行申请替换
  ak: 'gnkvfgm7dTMZz34MaslbOYGcRw8Srxuq'
})
Vue.config.productionTip = false


import Swiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import 'amfe-flexible';
import ElementUI from 'element-ui';



Vue.use(ElementUI);
Vue.use(Swiper);
import VueQuillEditor from 'vue-quill-editor'
// require styles 引入样式
// import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)
import api from "./apiNew/index"
Vue.prototype.$api = api
import util from "@/util/index.js"

Vue.prototype.$util = util

import jquery from "jquery";
Vue.prototype.$ = jquery;


AmapVue.config.version = '2.0';
AmapVue.config.key = '8ae5caa36f1ca98901b71527a4294f87';
Vue.use(AmapVue);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')