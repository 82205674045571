<template>
  <div class="app" id="app">
    <Nav v-if="!isMap"></Nav>
    <div>
      <router-view ref="mapRef" />
    </div>
    <Footer v-if="!isMap"></Footer>
  </div>
</template>
<script>
const whiteRouteList = ['/map', '/allMap', '/lingxi-warehouse']
export default {
  data() {
    return {
      isMap: true,
    };
  },
  watch: {
    $route(to, from) {
      this.isMap = whiteRouteList.includes(to.path);
    },
  },
  computed: {
    addClass() {
      if (window.innerWidth > 1280) {
        return "PC";
      } else {
        return "mobile";
      }
    },
  },
  methods: {
    handleUpdateMap(data) {
      if (this.$route.name === "Map") {
        this.$refs.mapRef.updateMap(data);
      }
    },
  },
};
</script>
<style lang="less">
// body *{-webkit-filter: grayscale(100%); /* webkit */-moz-filter: grayscale(100%); /*firefox*/-ms-filter: grayscale(100%); /*ie9*/-o-filter: grayscale(100%); /*opera*/filter: grayscale(100%);filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1); filter:gray; /*ie9- */}

#app {
  font-family: -apple-system, BlinkMacSystemFont, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

// @media screen and (min-width: 2560px) {
//   html {
//     font-size: 388px !important;
//   }
// }

// @media screen and (min-width: 1920px) {
//   html {
//     font-size: 354px !important;
//   }
// }

// @media screen and (min-width: 1600px) {
//   html {
//     font-size: 320px !important;
//   }
// }
// @media screen and (min-width: 1366px) {
//   html {
//     font-size: 300px !important;
//   }
// }
</style>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
</style>