<template>
    <div>
        <div class="mapsearch">
            <!-- 搜索框 -->

            <div style="display: flex;height: 50px;">
                <div style="height: 40px;width: 300px;">

                    <input style="width: 280px;height: 40px;padding-left:10px;border:1px solid #00B079;" type="text"
                        v-model="mapsearch" placeholder="搜索">
                </div>
                <div style="width: 100px;
height: 40px;
background: #00B079;
border-radius: 4px;line-height: 40px;text-align:center;font-size: 20px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;margin-left:10px;cursor: pointer;" @click="mapSearch">
                    搜索
                </div>
            </div>


            <!-- 搜索结果展示列表 -->
            <div id="r-result" style="border:1px solid #C0C0C0;width:150px;height:auto; display:none;"></div>
        </div>
        <div ref="mapContainer" style="width: 800px;height: 500px;margin-top:30px;"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            map: null, // 地图实例
            marker: null, // 标记点实例
            address: '', // 详细地址
            lng: '', // 经度
            lat: '', // 纬度
            mapsearch: ''
        }
    },
    activated() {
    },
    watch: {
    },
    created() {
    },
    mounted() {
        // 创建地图实例
        this.map = new BMap.Map(this.$refs.mapContainer)
        // 设置地图中心点和缩放级别
        this.map.centerAndZoom(new BMap.Point(116.404, 39.915), 11)
        // 添加地图控件
        this.map.addControl(new BMap.NavigationControl())
        // 添加地图事件监听
        this.map.addEventListener('click', this.handleMapClick)


    },
    methods: {
        mapSearch() {
            let t = this;
            console.log(1111)
            var local = new BMap.LocalSearch(this.map, {
                renderOptions: { map: this.map, panel: "r-result" }
            });
            local.search(t.mapsearch);
        },
        // 点击地图获取经纬度和详细地址
        handleMapClick(e) {
            // 获取点击的经纬度
            this.lng = e.point.lng
            this.lat = e.point.lat
            // 创建标记点实例
            if (this.marker) {
                this.map.removeOverlay(this.marker)
            }
            this.marker = new BMap.Marker(e.point)
            this.map.addOverlay(this.marker)
            // 根据经纬度获取详细地址
            const geoc = new BMap.Geocoder()
            geoc.getLocation(e.point, (rs) => {
                // console.log(rs);
                // console.log(this.mapsearch);
                const addComp = rs.addressComponents
                this.address = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber
                console.log(this.lng, this.lat, this.address);
                let name = ''
                if (addComp.streetNumber != '') {
                    name = addComp.streetNumber
                } else if (addComp.street != '') {

                    name = addComp.street
                } else {
                    name = addComp.district
                }
                this.$emit('location', {
                    lng: this.lng,
                    lat: this.lat,
                    name: this.mapsearch,
                    address: this.address
                })
            })
        },
    }


}
</script>
<style>
input {
    outline: none;
}
</style>
