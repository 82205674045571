<template>
  <div class="card" @click="gotoDetail(info)">
    <div class="box card-box">
      <el-image
        style="width: 100%; height: 100%; background: #fff"
        :src="info.warehouseInfo.thumbnail"
        fit="cover"
      ></el-image>
      <div class="box-tip">{{ tipTxt }}</div>
      <div
        v-if="info.warehouseInfo.vrAddress"
        class="box-vr"
        :class="{ 'back-add': vr }"
      ></div>
      <div class="flex box-address">
        <div class="name">{{ info.warehouseInfo.warehouseName }}</div>

        <div class="icon"></div>
        <div class="location" v-if="info.warehouseInfo">
          {{ info.warehouseInfo.oneName }}
        </div>
      </div>
      <div class="bott flex spb">
        <div class="l" v-if="info.tagNameList && info.tagNameList[0]">
          {{ info.tagNameList[0] }}
        </div>
        <div class="r" v-if="info.tagNameList && info.tagNameList[1]">
          {{ info.tagNameList[1] }}
        </div>
        <div class="r" v-if="info.tagNameList && info.tagNameList[2]">
          {{ info.tagNameList[2] }}
        </div>
        <div class="l" v-if="info.warehouseInfo && info.warehouseInfo.tags[0]">
          {{ info.warehouseInfo.tags[0].itemName }}
        </div>
        <div class="r" v-if="info.warehouseInfo && info.warehouseInfo.tags[1]">
          {{ info.warehouseInfo.tags[1].itemName }}
        </div>
        <div class="r" v-if="info.warehouseInfo && info.warehouseInfo.tags[2]">
          {{ info.warehouseInfo.tags[2].itemName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card-s",
  props: {
    bottomText: {
      type: String,
      default: "已上线货兑宝",
    },
    tipTxt: {
      type: String,
      default: "热租",
    },
    zoom: {
      type: Number,
      default: 1,
    },
    vr: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      warehouseInfo: {},
    };
  },

  methods: {
    gotoDetail(e) {
      this.$router.push({
        path: "/detail",
        query: {
          id: this.info.warehouseId,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  margin-right: 37px;
  &:nth-child(4n) {
    // 去除第3n个的margin-right
    margin-right: -10px;
  }
}
</style>
