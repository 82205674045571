<template>
    <div>
        <nav id="nav">
            <div class="nav flex spb">
                <div class="flex">
                    <div style="cursor: pointer;">
                        <div class="nav-logo" @click="addClassName('/')"></div>
                    </div>
                    <div style="margin-left:30px;" class="nav-line"></div>
                    <div class="flex spb" style="line-height: 36px;height: 36px;">
                        <div class="nav-title last" to="" @click="addClassName('https://www.cmstjd.com/')">
                            中储京科
                        </div>
                        <div class="flex">
                            <div>

                                <el-dropdown trigger="hover">
                                    <!-- op/top-start/top-end/bottom/bottom-start/bottom-end -->
                                    <span class="nav-title">
                                        找仓租仓
                                    </span>
                                    <el-dropdown-menu class="my-dropdown-menu" slot="dropdown">
                                        <el-dropdown-item>
                                            <router-link to="/map">地图找库</router-link>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <span @click="goAsk()">仓库求租</span>
                                            <!-- <router-link to="/askforrent">仓库求租</router-link> -->
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <!-- <router-link class="nav-title" to="/recruitment">人才招聘</router-link> -->
                            <div class="nav-title" @click="goRec()">人才招聘</div>
                            <!-- <router-link class="nav-title" to="/jinrong">金融产品</router-link> -->
                            <div class="nav-title" @click="goJin()">金融产品
                            </div>
                            <div class="nav-title" @click="goLx()">灵犀超链</div>
                        </div>
                    </div>

                </div>
                <div class="flex" style="align-items: center;height: 36px;">
                    <div class="nav-phone-icon"></div>
                    <div class="nav-phone-num" style="font-size: 14px;">400-673-7868 <span style="font-size: 14px;">转</span> 3</div>
                    <div class="gobtn gohdb" @click="jump('https://www.huoduibao.com/')">货兑宝</div>
                    <div style="position: relative;">
                        <el-dropdown placement="top-start" trigger="hover">
                            <div class="gobtn gofb" style="color:#FFFFFF;">
                                信息发布
                            </div>
                            <el-dropdown-menu class="my-dropdown-menu" slot="dropdown">
                                <el-dropdown-item>
                                    <div @click="rentShow(1)">
                                        <div>仓库出租</div>
                                        <div style="font-size:12px;color:#999999;">(需企业认证)</div>
                                    </div>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <div @click="rentShow(2)">
                                        <div>人才招聘</div>
                                        <div style="font-size:12px;color:#999999;">(需企业认证)</div>
                                    </div>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <div @click="rentShow(3)">
                                        <div>仓库求租</div>
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>

                    </div>
                    <div class="godl" style="color: #FFFFFF;" v-if="this.token">
                        <router-link class="nav-title" to="/personal">个人中心</router-link>
                    </div>
                    <div v-else class="godl" style="color: #FFFFFF;" @click="dialogVisible = true"><span
                            class="nav-title">登录</span> </div>
                </div>
                <div v-if="dialogVisible">
                    <el-dialog top="160px" class="my-dialog" title="验证码登录" :center="true" :visible.sync="dialogVisible"
                        width="700px" :before-close="handleClose">
                        <div class="form">
                            <div class="tel"><input maxlength="11" v-model="param.phone" placeholder="请输入手机号"></div>
                            <div class="code">
                                <input :maxlength="6" v-model="param.code" placeholder="请输入验证码">
                                <button v-bind:class="{ grey: isGrey, blue: !isGrey }" v-bind:disabled="dis" type="primary"
                                    @click="getCode('code')">
                                    <span v-if="show">发送验证码</span>
                                    <span v-else>重新发送({{ count }}s)</span>
                                </button>
                            </div>
                            <div class="title">
                                验证即登录，未注册将自动创建账号
                            </div>
                            <div style="padding:0 100px;">
                                <div class="agree">
                                    <div class="imgs" @click="agreeShow = !agreeShow">
                                        <img v-if="agreeShow" src="@/assets/yes.png" alt="">
                                        <img v-else src="@/assets/no.png" alt="">
                                    </div>

                                    我已阅读并同意<span @click="jumpLaw('使用条款')">《使用条款》</span>和<span
                                        @click="jumpLaw('隐私声明')">《隐私声明》</span>
                                </div>
                                <div class="login">
                                    <button @click="loginByCode">登录</button>
                                </div>
                                <div class="other">
                                    <div class="register" @click="goRegister">
                                        我要注册
                                    </div>
                                    <div class="loginByPwd" @click="goLoginByPwd">
                                        密码登录
                                    </div>
                                </div>
                            </div>

                            <div class="loginByWx" @click="loginByEwm">
                                <img style="margin-right:5px;" src="@/assets/wx.png" alt="">微信扫码登录
                            </div>
                        </div>

                    </el-dialog>

                </div>
                <div v-if="dialogVisibleRegister">
                    <el-dialog top="160px" class="my-dialog" title="验证码注册" :center="true"
                        :visible.sync="dialogVisibleRegister" width="700px" :before-close="handleClose">
                        <div class="form">
                            <div class="tel"><input maxlength="11" v-model="param2.phone" placeholder="请输入手机号"></div>
                            <div class="code">
                                <input maxlength="6" v-model="param2.code" placeholder="请输入验证码">
                                <button v-bind:class="{ grey: isGrey, blue: !isGrey }" v-bind:disabled="dis" type="primary"
                                    @click="getCode('res')">
                                    <span v-if="show">发送验证码</span>
                                    <span v-else>重新发送({{ count }}s)</span>
                                </button>
                            </div>
                            <div class="title">
                                验证即登录，未注册将自动创建账号
                            </div>
                            <div style="padding:0 100px;">
                                <div class="agree">
                                    <div class="imgs" @click="agreeShow = !agreeShow">
                                        <img v-if="agreeShow" src="@/assets/yes.png" alt="">
                                        <img v-else src="@/assets/no.png" alt="">
                                    </div>

                                    我已阅读并同意<span @click="jumpLaw('使用条款')">《使用条款》</span>和<span
                                        @click="jumpLaw('隐私声明')">《隐私声明》</span>
                                </div>
                                <div class="login">
                                    <button @click="registerByCode">注册</button>
                                </div>
                                <div class="other">
                                    <div class="register" @click="goLoginByPwd">
                                        我要登录
                                    </div>
                                </div>
                            </div>

                            <div class="loginByWx" @click="loginByEwm">
                                <img style="margin-right:5px;" src="@/assets/wx.png" alt="">微信扫码登录
                            </div>
                        </div>
                    </el-dialog>
                </div>
                <div v-if="dialogVisiblePwd">
                    <el-dialog top="160px" class="my-dialog" title="密码登录" :center="true" :visible.sync="dialogVisiblePwd"
                        width="700px" :before-close="handleClose">
                        <div class="form">
                            <div class="tel"><input maxlength="11" v-model="param1.phone" placeholder="请输入手机号"></div>
                            <div class="code">
                                <input type="password" v-model="param1.pwd" placeholder="请输入密码">
                                <!-- <button>
                                    <span>忘记密码</span>
                                </button> -->
                            </div>
                            <div class="title">
                                验证即登录，未注册将自动创建账号
                            </div>
                            <div style="padding:0 100px">
                                <div class="agree">
                                    <div class="imgs" @click="agreeShow = !agreeShow">
                                        <img v-if="agreeShow" src="@/assets/yes.png" alt="">
                                        <img v-else src="@/assets/no.png" alt="">
                                    </div>

                                    我已阅读并同意<span @click="jumpLaw('使用条款')">《使用条款》</span>和<span
                                        @click="jumpLaw('隐私声明')">《隐私声明》</span>
                                </div>
                                <div class="login">
                                    <button @click="loginByPwd">登录</button>
                                </div>
                                <div class="other">
                                    <div class="register" @click="goRegister">
                                        我要注册
                                    </div>
                                    <div class="loginByPwd" @click="goLoginByCode">
                                        验证码登录
                                    </div>
                                </div>
                            </div>

                            <div class="loginByWx" @click="loginByEwm">
                                <img style="margin-right:5px;" src="@/assets/wx.png" alt="">微信扫码登录
                            </div>
                        </div>

                    </el-dialog>

                </div>
                <div v-if="dialogVisibleEwm">
                    <el-dialog top="160px" class="my-dialog" title="微信扫码 安全登录" :center="true"
                        :visible.sync="dialogVisibleEwm" width="700px" :before-close="handleClose">
                        <div class="form" style="padding-bottom: 30px;margin-top: -30px;">
                            <div class="ewm_img">
                                <iframe :src="url" width="100%" height="400px" frameborder="0"></iframe>
                            </div>
                            <!-- <div class="tip">
                                
                                <div class="tip_ico">
                                    <img src="@/assets/sao.png" alt="">
                                </div>
                                <div class="tip_con">
                                    <div class="every_con">打开微信</div>
                                    <div class="every_con">扫一扫登录</div>
                                </div>
                            </div> -->

                            <div class="loginByWx" @click="goLoginByCode">
                                <img style="margin-right:5px;" src="@/assets/tel.png" alt="">手机号登录
                            </div>

                        </div>

                    </el-dialog>

                </div>
                <div v-show="mapShow" class="modal">
                    <div class="modal-mask" @click="mapShow = false"></div>
                    <div class="modal-wrapper">
                        <div class="modal-container">
                            <span class="close" @click="mapShow = false">&times;</span>
                            <h2>地图选点</h2>
                            <div style="width: 800px;height: 600px;margin-top:30px;">
                                <Map @location="getMap"></Map>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>

        <div class="popup popup1" style="z-index: 0 !important;">
            <el-dialog :close-on-click-modal="false" top="160px" class="my-dialog" style="z-index: 0 !important;"
                title="仓库租赁信息发布" :center="true" :visible.sync="fabuEdit1" width="1100px" :before-close="handleCloseEdit">
                <div class="form" v-show="step == 1" @click="colseAll">
                    <div class="stepList">
                        <div class="step">
                            <img src="../../assets/fabu/jbxx1.png" alt="">
                            <img src="../../assets/fabu/tpxx.png" alt="">
                            <img src="../../assets/fabu/wzxx.png" alt="">
                        </div>
                    </div>
                    <div class="form_con ">
                        <el-form :model="paramRent" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm">
                            <div class="form_row">
                                <el-form-item class="every_form" label="仓库名称" prop="warehouseName">
                                    <el-input class="form_inp" placeholder="请输入" maxlength="20"
                                        v-model="paramRent.warehouseName"></el-input>
                                </el-form-item>
                                <el-form-item class="every_form" label="仓库经纪人" prop="brokerId">
                                    <el-select @change="changeBroker" class="form_inp" v-model="brokerName"
                                        placeholder="请选择">
                                        <el-option v-for="item in selectAll.broker" :key="item.brokerId"
                                            :label="item.brokerName" :value="item.brokerId"></el-option>

                                    </el-select>

                                </el-form-item>
                            </div>
                            <div class="form_row" style="display: flex;">
                                <!-- style="width: 500px;" -->
                                <el-form-item class="every_form" label="区域" prop="province">

                                    <el-cascader :props="propsRent" @change="changeFnRent"></el-cascader>

                                </el-form-item>

                                <el-form-item class="every_form" label="仓库地址" prop="address">
                                    <el-input class="form_inp" placeholder="请输入" v-model="paramRent.address"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" label="仓库性质" prop="properties">
                                    <el-select @change="changeNature" class="form_inp" v-model="natureName"
                                        placeholder="请选择">
                                        <el-option v-for="item in natureList" :key="item.value" :label="item.name"
                                            :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>


                            </div>







                            <div class="form_row">
                                <el-form-item class="every_form" style="width: 100%;" label="存放品类" prop="categoryIds">
                                    <div class="form_inp" style="position: relative;display: flex;width: 100%; ">
                                        <span @click.stop="cfpl = !cfpl">选择</span>
                                        <div v-if="cfpl"
                                            style="width: 340px; position: absolute;top: 40px;left: 0;z-index: 99999999999999999999;">
                                            <el-select style="width: 214px;" :multiple-limit="4"
                                                @change="changeCategoryRent" v-model="typeSelect" multiple
                                                placeholder="请选择(最多四个)">
                                                <el-option v-for="item in selectAll.common_category" :key="item.categoryId"
                                                    :label="item.categoryName" :value="item.categoryId">
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div style="width: calc(100% - 50px);">
                                            <div style="display: flex;flex-wrap:wrap;">
                                                <div style="padding: 0px 20px;margin-left: 10px;margin-bottom:10px; border: 1px solid #EEEEEE;color:#333333;border-radius: 4px;"
                                                    v-for="(item, index) in cateSelectList" :key="index">
                                                    {{ item }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </el-form-item>
                            </div>
                            <!-- <div class="form_row">
                                <el-form-item label="存放品类" prop="categoryIds">
                                    <div class="form_inp" style="position: relative;">
                                        <div>
                                            <el-select style="width: 214px;" :multiple-limit="4"
                                                @change="changeCategoryRent" v-model="typeSelect" multiple
                                                placeholder="请选择(最多四个)">
                                                <el-option v-for="item in selectAll.common_category" :key="item.categoryId"
                                                    :label="item.categoryName" :value="item.categoryId">
                                                </el-option>
                                            </el-select>
                                        </div>

                                    </div>
                                </el-form-item>
                            </div> -->
                            <div class="form_row" style="position: relative;">

                                <el-form-item class="every_form" label="仓库总占地面积" prop="landArea">
                                    <el-input
                                        :οninput="paramRent.landArea = paramRent.landArea.match(/\d+(\.\d{0,2})?/) ? paramRent.landArea.match(/\d+(\.\d{0,2})?/)[0] : ''"
                                        class="form_inp" placeholder="请输入"
                                        v-model="paramRent.landArea"></el-input><span>万平方米</span>
                                </el-form-item>


                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" label="露天货场面积" prop="outdoorsArea">
                                    <el-input type="number"
                                        :οninput="paramRent.outdoorsArea = paramRent.outdoorsArea.match(/\d+(\.\d{0,2})?/) ? paramRent.outdoorsArea.match(/\d+(\.\d{0,2})?/)[0] : ''"
                                        class="form_inp" placeholder="请输入"
                                        v-model="paramRent.outdoorsArea"></el-input><span>万平方米</span>
                                </el-form-item>
                                <el-form-item class="every_form" label="库房面积" prop="warehouseArea">
                                    <el-input
                                        :οninput="paramRent.warehouseArea = paramRent.warehouseArea.match(/\d+(\.\d{0,2})?/) ? paramRent.warehouseArea.match(/\d+(\.\d{0,2})?/)[0] : ''"
                                        class="form_inp" placeholder="请输入"
                                        v-model="paramRent.warehouseArea"></el-input><span>万平方米</span>
                                </el-form-item>

                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" style="width: 100%;" label="是否有铁路专用线" prop="railwayIsHave">
                                    <el-radio-group @input="changeTielu" class="form_inp" style="width: 30%;"
                                        v-model="paramRent.railwayIsHave">
                                        <el-radio :label="1">是</el-radio>
                                        <el-radio :label="0">否</el-radio>
                                    </el-radio-group>
                                    <el-input class="form_inp" :disabled="tieluDis" style="width: 20%;" placeholder="请输入"
                                        v-model="paramRent.railwayCount"></el-input><span
                                        style="margin-right: 10px;">条</span>
                                    <el-input
                                        :οninput="paramRent.railwayLength = paramRent.railwayLength.match(/\d+(\.\d{0,2})?/) ? paramRent.railwayLength.match(/\d+(\.\d{0,2})?/)[0] : ''"
                                        :disabled="tieluDis" class="form_inp" style="width: 20%;" placeholder="请输入"
                                        v-model="paramRent.railwayLength"></el-input><span>公里</span>

                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" style="width: 100%;" label="是否上线货兑宝">
                                    <el-radio-group class="form_inp" style="width: 30%;" v-model="paramRent.isCustomer">
                                        <el-radio :label="1">是</el-radio>
                                        <el-radio :label="0">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" style="width: 100%;" label="仓库类型" prop="warehouseType">
                                    <div class="form_inp" style="position: relative;display: flex;width: 100%; ">
                                        <span @click.stop="cklx = !cklx">选择</span>
                                        <div v-if="cklx"
                                            style="width: 340px; position: absolute;top: 40px;left: 0;z-index: 99999999999999999999;">
                                            <el-select :multiple-limit="4" class="form_inp" @change="changeType"
                                                style="position: absolute;top: 0;z-index: 0;" v-model="wareSelect" multiple
                                                placeholder="请选择(最多四个)">
                                                <el-option v-for="item in selectAll.warehouse_extra" :key="item.tagId"
                                                    :label="item.itemName" :value="item.tagId">
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div style="width: calc(100% - 50px);">
                                            <div style="display: flex;flex-wrap:wrap;">
                                                <div style="padding: 0px 20px;margin-left: 10px;margin-bottom:10px; border: 1px solid #EEEEEE;color:#333333;border-radius: 4px;"
                                                    v-for="(item, index) in wareSelectList" :key="index">
                                                    {{ item }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </el-form-item>
                            </div>

                            <div class="form_row" style="position: relative;">
                                <span
                                    style="position: absolute;color: #F56C6C;top: 7px;left: 44px;font-size: 20px;"></span><el-form-item
                                    label="物联网功能" prop="">
                                    <!-- <div class="form_inp" style="position: relative;">

                                        <span @click="wlw = !wlw">选择</span>
                                        <div v-if="wlw"
                                            style="width: 200px; position: absolute;top: 40px;left: 0;z-index: 99999999999999999999;">
                                            <el-select @change="changeEffect" style="position: absolute;top: 0;z-index: 0;"
                                                v-model="effectNameSelect" multiple placeholder="请选择">
                                                <el-option v-for="item in selectAll.warehouse_iot" :key="item.tagId"
                                                    :label="item.itemName" :value="item.tagId">
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </div> -->
                                    <el-checkbox-group @change="changeEffect" v-model="effectNameSelect">
                                        <el-checkbox v-for="item in selectAll.warehouse_iot" :key="item.tagId"
                                            :label="item.tagId" name="type">{{ item.itemName }}</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>

                            </div>
                            <div class="form_row" style="width: 100%;">

                                <el-form-item style="width: 100%;" label="特色标签">

                                    <el-checkbox-group @change="changeBQ" v-model="biaoqian">
                                        <el-checkbox v-for="item in selectAll.warehouse_feature" :key="item.tagId"
                                            :label="item.tagId" name="type">{{ item.itemName }}</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" label="防火等级" prop="fireRating">
                                    <el-select @change="changeLevel" class="form_inp" v-model="levelName" placeholder="请选择">
                                        <el-option v-for="item in levelList" :key="item.type" :label="item.name"
                                            :value="item.type"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item class="every_form" label="建筑结构" prop="propertyStructure">
                                    <el-select @change="changeJiegou" class="form_inp" v-model="jiegouName"
                                        placeholder="请选择">
                                        <el-option v-for="item in jiegouList" :key="item.type" :label="item.name"
                                            :value="item.type"></el-option>
                                    </el-select>
                                </el-form-item>


                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" label="地面承重" prop="bearing">
                                    <el-input
                                        :οninput="paramRent.bearing = paramRent.bearing.match(/\d+(\.\d{0,2})?/) ? paramRent.bearing.match(/\d+(\.\d{0,2})?/)[0] : ''"
                                        class="form_inp" placeholder="请输入"
                                        v-model="paramRent.bearing"></el-input><span>t/㎡</span>
                                </el-form-item>
                                <el-form-item class="every_form" label="库房顶高" prop="high">
                                    <el-input
                                        :οninput="paramRent.high = paramRent.high.match(/\d+(\.\d{0,2})?/) ? paramRent.high.match(/\d+(\.\d{0,2})?/)[0] : ''"
                                        class="form_inp" placeholder="请输入"
                                        v-model="paramRent.high"></el-input><span>m</span>
                                </el-form-item>

                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" label="月台宽度" prop="railwayPlatformWidth">
                                    <el-input
                                        :οninput="paramRent.railwayPlatformWidth = paramRent.railwayPlatformWidth.match(/\d+(\.\d{0,2})?/) ? paramRent.railwayPlatformWidth.match(/\d+(\.\d{0,2})?/)[0] : ''"
                                        class="form_inp" placeholder="请输入(若无数据填写0)"
                                        v-model="paramRent.railwayPlatformWidth"></el-input><span>m</span>
                                </el-form-item>
                                <el-form-item class="every_form" label="月台高度" prop="platformHigh">
                                    <el-input
                                        :οninput="paramRent.platformHigh = paramRent.platformHigh.match(/\d+(\.\d{0,2})?/) ? paramRent.platformHigh.match(/\d+(\.\d{0,2})?/)[0] : ''"
                                        class="form_inp" placeholder="请输入(若无数据填写0)"
                                        v-model="paramRent.platformHigh"></el-input><span>m</span>
                                </el-form-item>

                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" label="雨棚宽度" prop="deep">
                                    <el-input
                                        :οninput="paramRent.deep = paramRent.deep.match(/\d+(\.\d{0,2})?/) ? paramRent.deep.match(/\d+(\.\d{0,2})?/)[0] : ''"
                                        class="form_inp" placeholder="请输入(若无数据填写0)"
                                        v-model="paramRent.deep"></el-input><span>m</span>
                                </el-form-item>

                                <el-form-item class="every_form" label="升降平台" prop="columnSpacing">
                                    <el-select class="form_inp" v-model="paramRent.columnSpacing" placeholder="请选择">
                                        <el-option label="有" :value="1"></el-option>
                                        <el-option label="无" :value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="服务功能" prop="service">
                                    <el-input style="height: 100px;" type="textarea" placeholder="每项功能中间用/隔开，例如功能1/功能2/功能3"
                                        v-model="paramRent.service"></el-input>
                                </el-form-item>
                            </div>
                            <div style="padding-bottom: 50px;" class="fuwenben">

                                <el-form-item label="仓库简介" prop="description">
                                    <quill-editor v-model="paramRent.description" ref="myQuillEditor"
                                        :options="editorOption" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
                                        @change="onEditorChange($event)">submitForm
                                    </quill-editor>
                                </el-form-item>

                            </div>
                            <div class="form_row" style="width: 100%;">

                                <el-form-item style="width: 80%;text-align: center;">
                                    <el-button style="width: 60%;" type="primary"
                                        @click.stop="submitForm('ruleForm')">下一步</el-button>
                                </el-form-item>
                            </div>





                        </el-form>

                    </div>
                </div>
                <div class="form" v-show="step == 2">
                    <div class="stepList">
                        <div class="step">
                            <img src="../../assets/fabu/jbxx1.png" alt="">
                            <img src="../../assets/fabu/tpxx1.png" alt="">
                            <img src="../../assets/fabu/wzxx.png" alt="">
                        </div>

                    </div>
                    <div class="form_con1" style="width: 74%;padding-top:50px;margin:0 auto;">

                        <el-form :model="paramRent" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                            <div class="form_row">
                                <span style="color:red;position: relative;top: 32px;left: 12px;">*</span><el-form-item
                                    class="every_form" style="padding-left:8px;position: relative;" label="仓库封面图">


                                    <el-upload :on-exceed="handleChange1" :accept="'.jpg,.png'" :limit="1"
                                        class="upload-demo" :action="uploadPath + '/system/front/upload_file'"
                                        :on-preview="handlePreview" :on-success="uploadFengmian"
                                        :before-upload="handleBefore" :on-remove="handleRemove" :file-list="fileList1"
                                        list-type="picture">
                                        <div style="display: flex;position: relative;">
                                            <el-button style="width: 100px;height: 40px;" size="small"
                                                type="primary">点击上传</el-button>
                                            <div style="padding:8px 0;margin-left:10px;">
                                                <img @mouseenter="showWarn1 = !showWarn1"
                                                    @mouseleave="showWarn1 = !showWarn1" style="width: 24px;height: 24px;"
                                                    src="@/assets/person/warn.png" alt="" slot="reference">

                                            </div>
                                            <div class="title_other" v-if="showWarn1">
                                                1.图片上传格式为jpg、png<br>
                                                2.建议图片单张大小在5M以内<br>
                                                3.图片宽高比建议采用3:2<br>
                                                4.图片名称不宜过长

                                            </div>
                                        </div>

                                    </el-upload>
                                    <div style="position: absolute;top: 2px;left: 150px;width: 200rpx;">

                                        <a href="https://www.secaibi.com/tools/%e5%9c%a8%e7%ba%bf%e5%9b%be%e7%89%87%e5%8e%8b%e7%bc%a9/"
                                            target="_blank">前往在线压缩地址</a>

                                    </div>





                                </el-form-item>


                            </div>

                            <div class="form_row" style="display: flex;align-items:flex-start">
                                <span style="color:red;position: relative;top: 12px;left: 30px;">*</span><el-form-item
                                    class="every_form" style="width: 100%;position: relative;" label="图片预览" prop="name">
                                    <el-upload :on-exceed="handleChangess" :multiple="true" :limit="5" :accept="'.jpg,.png'"
                                        class="upload-demo" :action="uploadPath + '/system/front/upload_file'"
                                        :on-preview="handlePreview" :on-success="uploadYulan" :before-upload="handleBefore"
                                        :on-remove="handleRemove1" :file-list="fileList2" list-type="picture">
                                        <div style="display: flex;position: relative;">
                                            <el-button style="width: 100px;height: 40px;" size="small"
                                                type="primary">点击上传</el-button>
                                            <div style="padding:8px 0;margin-left:10px;">
                                                <img @mouseenter="showWarn2 = !showWarn2"
                                                    @mouseleave="showWarn2 = !showWarn2" style="width: 24px;height: 24px;"
                                                    src="@/assets/person/warn.png" alt="" slot="reference">
                                            </div>
                                            <div class="title_other" v-if="showWarn2">
                                                1.图片上传格式为jpg、png<br>
                                                2.建议图片单张大小在5M以内<br>
                                                3.图片宽高比建议采用3:2<br>
                                                4.图片名称不宜过长<br>
                                                5.图片上传张数最多5张
                                            </div>
                                        </div>

                                    </el-upload>
                                    <div style="position: absolute;top: 3px;left: 150px;width: 200rpx;">

                                        <a href="https://www.secaibi.com/tools/%e5%9c%a8%e7%ba%bf%e5%9b%be%e7%89%87%e5%8e%8b%e7%bc%a9/"
                                            target="_blank">前往在线压缩地址</a>

                                    </div>
                                </el-form-item>

                            </div>



                            <div class="form_row">
                                <el-form-item class="every_form" label="VR预览" prop="name">
                                    <div style="font-size: 16px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #CCCCCC;">VR实景预览能更好的展示仓库各方位信息，以便于出租仓库，如需此功能请联系客服</div>
                                    <div class="btn" style="display: flex;justify-content:space-between;padding:50px;">
                                        <div style="width: 35%;line-height: 48px;text-align:center;cursor: pointer;
height: 48px;
background: #00B079;
border-radius: 10px 10px 10px 10px;font-size: 20px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;border:none;" @click="step = 1">上一步</div>
                                        <div style="width: 35%;
height: 48px;
background: #00B079;
border-radius: 10px 10px 10px 10px;font-size: 20px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;border:none;text-align:center;line-height: 48px;cursor: pointer;" @click="tupianNext">下一步</div>
                                    </div>
                                </el-form-item>


                            </div>
                        </el-form>


                    </div>
                </div>
                <div class="form" v-show="step == 3">
                    <div class="stepList">
                        <div class="step">
                            <img src="../../assets/fabu/jbxx1.png" alt="">
                            <img src="../../assets/fabu/tpxx1.png" alt="">
                            <img src="../../assets/fabu/wzxx1.png" alt="">
                        </div>
                    </div>
                    <div class="form_con" style="padding: 0 45px;">
                        <el-form :model="paramRent" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm">
                            <div class="form_row">

                                <el-form-item class="every_form" label="经纬度拾取地址">
                                    <div class="form_inp">
                                        <span @click="selectLat">选择</span>
                                    </div>
                                </el-form-item>
                            </div>

                            <div class="form_row">
                                <span style="color:red;position: relative;top: 12px;left: 68px;">*</span><el-form-item
                                    class="every_form" label="仓库经度" prop="name">
                                    <el-input disabled style="width: 240px;" placeholder="请输入"
                                        v-model="paramRent.latitude"></el-input>
                                </el-form-item>

                            </div>
                            <div class="form_row">

                                <span style="color:red;position: relative;top: 12px;left: 68px;">*</span><el-form-item
                                    class="every_form" label="仓库纬度" prop="region">
                                    <el-input disabled style="width: 240px;" placeholder="请输入"
                                        v-model="paramRent.longitude"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item label="关联地点:">
                                    <div style="display: flex;">
                                        <div>
                                            例如仓库附近的港口，车站等
                                        </div>
                                        <div style="width: 92px;margin-left:50px;text-align:center;
height: 42px;
background: #00B079;
border-radius: 10px 10px 10px 10px;color:#FFFFFF;border:none;cursor: pointer;" @click="addAddress">添加</div>
                                    </div>

                                </el-form-item>

                            </div>
                            <div style="padding:0 0px;background-color: #00B079;" v-if="addFlag">
                                <el-table ref="singleTable" :data="addList" highlight-current-row style="width: 100%">
                                    <el-table-column label="序号" type="index" width="60">
                                    </el-table-column>


                                    <el-table-column @change="changeAddressTypes" label="类别" width="160" prop="typeName">
                                        <template slot-scope="scope">
                                            <el-select style="z-index: 9999999999999999 !important;"
                                                @change="handleEdit(scope.$index, scope.row)" v-model="scope.row.type"
                                                placeholder="请选择">
                                                <el-option v-for="item in selectAll.map" :key="item.tagId"
                                                    :label="item.itemName" :value="item.tagId"></el-option>
                                            </el-select>
                                        </template>

                                    </el-table-column>
                                    <el-table-column prop="name" label="名称">
                                    </el-table-column>
                                    <el-table-column prop="address" label="地址">
                                    </el-table-column>
                                    <el-table-column prop="latitude" label="经度" width="110">
                                    </el-table-column>
                                    <el-table-column prop="longitude" label="纬度" width="110">
                                    </el-table-column>
                                    <el-table-column prop="distance" label="距离(km)" width="80">
                                    </el-table-column>
                                    <el-table-column label="操作">

                                        <template slot-scope="scope">

                                            <el-button size="mini" type="danger"
                                                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>

                            </div>

                            <div class="form_row" style="margin-top:50px;">
                                <div class="btn"
                                    style="display: flex;justify-content:space-between;padding:0px 100px;width: calc(100% - 200px);">
                                    <div style="width: 35%;line-height: 48px;text-align:center;cursor: pointer;
height: 48px;
background: #00B079;
border-radius: 10px 10px 10px 10px;font-size: 20px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;border:none;" @click="step = 2">上一步</div>
                                    <div style="width: 35%;
height: 48px;
text-align:center;
line-height: 48px;
background: #00B079;
border-radius: 10px 10px 10px 10px;font-size: 20px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;cursor: pointer;
color: #FFFFFF;border:none;" @click="fabuRent">确认发布</div>
                                </div>


                            </div>
                        </el-form>
                    </div>
                </div>
            </el-dialog>
        </div>
        <div class="popup popup3">
            <el-dialog top="160px" :close-on-click-modal="false" class="my-dialog" title="岗位招聘信息发布"
                :visible.sync="fabuEdit2" width="900px" center :before-close="handleCloseEdit">
                <div class="form" v-show="step2 == 1">
                    <div class="stepList">
                        <div class="step">
                            <img src="../../assets/person/jbxx.png" alt="">
                            <img src="../../assets/person/gwxx.png" alt="">
                        </div>
                    </div>
                    <div class="form_con">
                        <el-form ref="form" :model="paramRec" label-width="84px">
                            <div class="form_row">
                                <el-form-item label="岗位类别" style="margin-left:50px;">
                                    <el-cascader placeholder="请选择" style="width:553px;"
                                        :props="{ children: 'child', value: 'id', label: 'className' }" v-model="classValue"
                                        :options="classList1" @change="handleChange"></el-cascader>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item label="岗位名称" style="margin-left:50px;">
                                    <el-input placeholder="请输入" style="width:553px;" v-model="paramRec.title"></el-input>
                                </el-form-item>
                            </div>
                            <div style="border-bottom:1px solid #CCCCCC;margin:10px 20px 30px 20px"></div>

                            <!-- <div>
                                <el-form-item label="岗位描述" style="width: 100%;margin-left:50px;">
                                    <el-input type="textarea" placeholder="请输入" style="width: 553px;"
                                        v-model="paramRec.desc"></el-input>
                                </el-form-item>
                            </div> -->
                            <div>
                                <el-form-item label="岗位描述" style="width: 100%;margin-left:50px;">

                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="工作内容" style="width: 100%;margin-left:50px;">
                                    <el-input placeholder="请输入" type="textarea" style="width: 553px;"
                                        v-model="paramRec.desc"></el-input>
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="岗位要求" style="width: 100%;margin-left:50px;">
                                    <el-input placeholder="请输入" type="textarea" style="width: 553px;"
                                        v-model="paramRec.postRequire"></el-input>
                                </el-form-item>
                            </div>



                            <div style="border-bottom:1px solid #CCCCCC;margin:30px 20px"></div>
                            <div class="form_row">
                                <el-form-item label="公司名称" style="margin-left:50px;">
                                    <el-input placeholder="请输入" style="width:553px;"
                                        v-model="paramRec.companyName"></el-input>
                                </el-form-item>
                            </div>
                            <!-- <div class="form_row">
                                <el-form-item label="福利待遇" style="width: 100%; margin-left:50px;">
                                    <el-checkbox-group @change="changeFuli" v-model="fuli">
                                        <el-checkbox v-for="item in selectAll.recruit_welfare" :key="item.tagId"
                                            :label="item.tagId" name="type">{{ item.itemName }}</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </div> -->
                            <!-- <div>
                                    <el-form-item label="岗位要求" >
                                        <el-input placeholder="请输入" type="textarea" style="width: 553px;"
                                            v-model="paramRec.postRequire"></el-input>
                                    </el-form-item>
                                </div> -->
                            <div>
                                <el-form-item label="福利待遇" style="width: 100%;margin-left:50px;">
                                    <el-input style="height: 100px;width: 553px;" type="textarea"
                                        placeholder="每项福利中间用/隔开，例如福利1/福利2/福利3" v-model="paramRec.welfare"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form_row" style="padding:50px 0;">
                                <div style="width: 50%;margin:0 auto;" class="btnQueren" @click="nextRec">下一步</div>
                            </div>
                        </el-form>
                    </div>
                </div>
                <div class="form" v-show="step2 == 2">
                    <div class="stepList">
                        <div class="step">
                            <img src="../../assets/person/jbxx.png" alt="">
                            <img src="../../assets/person/gwxx1.png" alt="">
                        </div>
                    </div>
                    <div class="form_con" style="padding:0 30px; ">
                        <el-form ref="paramRec" :model="paramRec" label-width="90px">
                            <div>
                                <el-form-item label="薪资范围">
                                    <el-radio-group v-model="paramRec.isFace" style="width:40%;">

                                        <el-radio :label="0">
                                            <div style="width: 300px;height: 40px;font-size:18px;color:#333333;">
                                                <el-input type="number" style="width:104px" placeholder="最低薪资"
                                                    v-model="paramRec.salaryMin"></el-input>&nbsp;K&nbsp; ~
                                                <el-input type="number" style="width:104px;" placeholder="最高薪资"
                                                    v-model="paramRec.salaryMax"></el-input>&nbsp;K
                                            </div>
                                        </el-radio>


                                        <el-radio :label="1">面议</el-radio>
                                    </el-radio-group>


                                </el-form-item>
                            </div>
                            <div class="form_row" style="display: flex;">
                                <el-form-item class="every_form" label="学历要求">
                                    <el-select style="width: 208px;" @change="changeXueli" class="form_inp"
                                        v-model="xueliName" placeholder="请选择">
                                        <el-option v-for="item in selectAll.warehouse_education" :key="item.tagId"
                                            :label="item.itemName" :value="item.tagId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item class="every_form" label="工作年限">
                                    <el-select @change="changeNianxian1" style="width: 100px;"
                                        v-model="paramRec.workYearMin" placeholder="请选择">
                                        <el-option v-for="item in nianxianList" :key="item.type" :label="item.name"
                                            :value="item.type"></el-option>
                                    </el-select>&nbsp;~

                                    <el-select style="width: 100px;" v-model="paramRec.workYearMax" placeholder="请选择">
                                        <el-option @change="changeNianxian2" v-for="item in nianxianList" :key="item.type"
                                            :label="item.name" :value="item.type"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="form_row">
                                <el-form-item label="工作时间" style="width: 100%;">
                                    <el-time-select style="width: 208px;" placeholder="起始时间"
                                        v-model="paramRec.workTimeBegin" :picker-options="{
                                            start: '08:30',
                                            step: '00:15',
                                            end: '18:30'
                                        }">
                                    </el-time-select>&nbsp;至
                                    <el-time-select style="width: 208px;" placeholder="结束时间" v-model="paramRec.workTimeEnd"
                                        :picker-options="{
                                            start: '08:30',
                                            step: '00:15',
                                            end: '18:30',
                                            minTime: paramRec.workTimeBegin
                                        }">
                                    </el-time-select>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" label="工作地区">
                                    <el-cascader style="width: 208px;" :props="propsRec"
                                        @change="changeFnRec"></el-cascader>
                                </el-form-item>
                                <el-form-item class="every_form" label="详细地址">
                                    <el-input placeholder="请输入" v-model="paramRec.address" style="width: 208px;"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item label="联系人" style="width: 50%;">
                                    <el-input placeholder="请输入" v-model="paramRec.contactName"
                                        style="width: 208px;"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item label="联系电话" style="width: 50%;">
                                    <el-input placeholder="请输入" autocomplete="off"
                                        oninput="value=value.replace(/[^0-9]/g,'')" maxlength="11"
                                        v-model="paramRec.contactWay" style="width: 208px;"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form_row" style="padding:50px 0;display: flex;">
                                <div style="width: 40%;margin:0 auto;" class="btnQueren" @click="step2 = 1">上一步</div>
                                <div style="width: 40%;margin:0 auto;" class="btnQueren" @click="fabuRec">确认发布</div>
                            </div>
                        </el-form>
                    </div>
                </div>
            </el-dialog>
        </div>
        <div class="popup popup2">
            <el-dialog :close-on-click-modal="false" top="160px" class="my-dialog" title="求租信息发布" :visible.sync="fabuEdit3"
                width="900px" center :before-close="handleCloseEdit">
                <div class="form_con" style="width:840px; margin: 0 auto;" @click="colseAll">
                    <el-form ref="form" :model="paramRec" label-width="120px" label-position="right">
                        <div class="form_row">
                            <el-form-item label="基本信息">
                            </el-form-item>
                        </div>

                        <div class="form_row">

                            <el-form-item label="标题">
                                <el-input v-model="paramAsk.title" :readonly="true" placeholder="无需填写"
                                    style="width: 546px;"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form_row">
                            <el-form-item label="求租面积">
                                <el-select @change='changeAreaMin' style="width: 208px;" v-model="qiuzumianji"
                                    placeholder="300~500㎡">
                                    <el-option v-for="item in areaList" :key="item.type" :label="item.name"
                                        :value="item.type">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="every_form" label="求租地区">
                                <el-cascader placeholder="请选择" :props="props" @change="changeFn"></el-cascader>
                            </el-form-item>

                        </div>

                        <div class="form_row">
                            <el-form-item class="every_form" label="起租日期">
                                <el-date-picker style="width: 208px;" v-model="paramAsk.rentBeginTime" type="date"
                                    placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>

                        </div>
                        <div style="width: 80%; border-bottom:1px solid #CCCCCC;margin:50px auto"></div>
                        <div class="form_row">
                            <el-form-item label="求租方信息">
                            </el-form-item>
                        </div>
                        <div class="form_row">
                            <el-form-item class="every_form" label="公司名称">
                                <el-input v-model="paramAsk.companyName" class="form_inp" placeholder="请输入"
                                    style="width: 208px;"></el-input>
                            </el-form-item>
                            <el-form-item class="every_form" label="求租人">
                                <el-input v-model="paramAsk.seekRenterName" class="form_inp" placeholder="请输入"
                                    style="width: 208px;"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form_row">
                            <el-form-item class="every_form" label="联系方式">
                                <el-input v-model="paramAsk.seekRenterContact" autocomplete="off" lass="form_inp"
                                    placeholder="请输入" style="width: 208px;"></el-input>
                            </el-form-item>
                        </div>
                        <div style="width: 80%; border-bottom:1px solid #CCCCCC;margin:50px auto"></div>

                        <div class="form_row">
                            <el-form-item label="求租方需求">
                            </el-form-item>
                        </div>
                        <div class="form_row">
                            <el-form-item class="every_form" label="预计租期">
                                <el-select style="width: 208px;" v-model="paramAsk.rentTerm" placeholder="请选择">
                                    <el-option v-for="item in datePlan" :key="item.names" :label="item.name"
                                        :value="item.names">
                                    </el-option>
                                </el-select>

                            </el-form-item>
                            <el-form-item class="every_form" label="仓库用途">
                                <el-input v-model="paramAsk.ypWarehouseUseTo" lass="form_inp" placeholder="例如：存放货物"
                                    style="width: 208px;"></el-input>

                            </el-form-item>


                        </div>





                        <div class="form_row">

                            <el-form-item class="every_form" style="width: 100%;" label="仓库类型">
                                <div class="form_inp" style="position: relative;display: flex;width: 100%; ">
                                    <span @click.stop="cklx1 = !cklx1" style="cursor: pointer;
                            font-size: 16px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #00B079;">选择</span>
                                    <div v-if="cklx1"
                                        style="width: 340px; position: absolute;top: 40px;left: 0;z-index: 99999999999999999999;">
                                        <el-select :multiple-limit="4" @change="changeHouse"
                                            style="position: absolute;top: 0;z-index: 0;width: 208px;" v-model="houseSelect"
                                            multiple placeholder="请选择(最多四个)">
                                            <el-option v-for="item in selectAll.warehouse_extra" :key="item.tagId"
                                                :label="item.itemName" :value="item.tagId">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div style="width: calc(100% - 50px);">
                                        <div style="display: flex;flex-wrap:wrap;">
                                            <div style="padding: 0px 20px;margin-left: 10px;margin-bottom:10px; border: 1px solid #EEEEEE;color:#333333;border-radius: 4px;"
                                                v-for="(item, index) in typeSelectList1" :key="index">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </el-form-item>

                        </div>



                        <div class="form_row">

                            <el-form-item class="every_form" style="width: 100%;" label="存放品类">
                                <div class="form_inp" style="position: relative;display: flex;width: 100%; ">
                                    <span @click.stop="cklx2 = !cklx2" style="cursor: pointer;
                            font-size: 16px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #00B079;">选择</span>
                                    <div v-if="cklx2"
                                        style="width: 340px; position: absolute;top: 40px;left: 0;z-index: 99999999999999999999;">
                                        <el-select :multiple-limit="4" style="width: 208px;" @change="changeCategoryAsk"
                                            v-model="typeSelect" multiple placeholder="请选择(最多四个)">
                                            <el-option v-for="item in selectAll.common_category" :key="item.categoryId"
                                                :label="item.categoryName" :value="item.categoryId">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div style="width: calc(100% - 50px);">
                                        <div style="display: flex;flex-wrap:wrap;">
                                            <div style="padding: 0px 20px;margin-left: 10px;margin-bottom:10px; border: 1px solid #EEEEEE;color:#333333;border-radius: 4px;"
                                                v-for="(item, index) in typeSelectList2" :key="index">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </el-form-item>

                        </div>

                        <div style="width: 80%; border-bottom:1px solid #CCCCCC;margin:50px auto"></div>
                        <div style="padding-left:50px">
                            <el-form-item label="其他内容">
                                <el-input type="textarea" placeholder="请输入" v-model="paramAsk.other"
                                    style="width: 540px;"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form_row" style="padding:50px 0;text-align:center;">
                            <div style="width: 400px;margin:0 auto;" class="btnQueren" @click.stop="fabuAsk">
                                确认发布</div>
                        </div>

                    </el-form>

                </div>
            </el-dialog>
        </div>
        <div class="shenhe">
            <el-dialog @close="closeShenhe" :close-on-click-modal="false" top="160px" :title="titleTan"
                :visible.sync="shenhe" width="700px" center>
                <div class="shenheimg">
                    <img src="@/assets/person/shenhe.png" alt="">
                </div>
                <div class="buttn" @click="shenhe = false">
                    我知道了
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import axios from "axios";
import Map from "../../components/myMap/index.vue";
let id = 0;
export default {

    components: {
        Map
    },
    data() {
        return {
            uploadPath: process.env.VUE_APP_BASE_API,
            titleTan: '仓库租赁信息发布',
            wareSelectList: [],
            cateSelectList: [],
            typeSelectList1: [],
            typeSelectList2: [],
            houseSelect: [],
            shenhe: false,
            fileList1: [],
            fileList2: [],
            classValue: [],
            ssqArea: '',
            map: null, // 地图实例
            marker: null, // 标记点实例
            address: '', // 详细地址
            lng: '', // 经度
            lat: '', // 纬度
            showWarn2: false,
            showWarn1: false,
            step2: 1,
            navOffsetTop: 0,
            dialogVisible: false,
            dialogVisiblePwd: false,
            dialogVisibleRegister: false,
            dialogVisibleEwm: false,
            agreeShow: false,
            dis: false,
            show: true,
            isGrey: false, //按钮样式
            timer: null, //设置计时器,
            count: "",
            param: {
                loginType: 1,
                phone: '',
                code: ''
            },
            param1: {
                loginType: 2,
                phone: '',
                pwd: ''
            },
            token: '',
            param2: {
                loginType: 1,
                phone: '',
                code: ''
            },
            url: '',



            fabuEdit1: false,
            fabuEdit2: false,
            fabuEdit3: false,
            map: null,
            mapShow: false,
            mapOne: 1,
            addressValue: '',
            cityValue: '',
            areaValue: '',
            fileList: [],
            cfpl: false,
            cklx: false,
            cklx1: false,
            cklx2: false,
            wlw: false,
            cklx: false,
            content: '',
            editorOption: {
                placeholder: "请编辑内容",
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        // ["blockquote", "code-block"],
                        // [{ list: "ordered" }, { list: "bullet" }],
                        // [{ script: "sub" }, { script: "super" }],
                        // [{ indent: "-1" }, { indent: "+1" }],
                        [{ size: ["small", false, "large", "huge"] }],
                        // [{ font: [] }],
                        // [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        // ["image"]
                    ]
                }
            },
            rules: {

                brokerId: [
                    { required: true, message: '请选择仓库经纪人', trigger: 'change' }
                ],
                province: [
                    { required: true, message: '请选择区域', trigger: 'change' }
                ],
                properties: [
                    { required: true, message: '请选择仓库性质', trigger: 'change' }
                ],
                columnSpacing: [
                    { required: true, message: '是否有升降平台', trigger: 'change' }
                ],
                warehouseType: [
                    { required: true, message: '请选择仓库类型', trigger: 'change' }
                ],
                // iotAbility: [
                //     // { required: true, message: '请选择物联网功能', trigger: 'change' }
                //     { type: 'array', required: false, message: '请至少选择一个联网功能', trigger: 'change' }
                // ],
                fireRating: [
                    { required: true, message: '请选择防火等级', trigger: 'change' }
                ],
                propertyStructure: [
                    { required: true, message: '请选择建筑结构', trigger: 'change' }
                ],
                categoryIds: [
                    { required: true, message: '请选择仓库品类', trigger: 'change' }
                ],
                warehouseName: [
                    { required: true, message: '请输入仓库名称，应为2到20个字符', trigger: 'blur' },
                ],
                address: [
                    { required: true, message: '请输入仓库地址', trigger: 'blur' },
                ],
                landArea: [
                    { required: true, message: '请输入仓库总占地面积', trigger: 'blur' },
                ],
                outdoorsArea: [
                    { required: true, message: '请输入露天货场面积', trigger: 'blur' },
                ],
                warehouseArea: [
                    { required: true, message: '请输入库房面积', trigger: 'blur' },
                ],
                bearing: [
                    { required: true, message: '请输入地面承重', trigger: 'blur' },
                ],
                high: [
                    { required: true, message: '请输入库房高度', trigger: 'blur' },
                ],
                railwayPlatformWidth: [
                    { required: true, message: '请输入月台宽度', trigger: 'blur' },
                ],
                platformHigh: [
                    { required: true, message: '请输入月台高度', trigger: 'blur' },
                ],
                deep: [
                    { required: true, message: '请输入雨棚深度', trigger: 'blur' },
                ],
                service: [
                    { required: true, message: '请输入服务功能', trigger: 'blur' },
                ],



            },
            tieluDis: true,
            value: '',
            ruleRent: [{
                name: "warehouseName",
                checkType: "isNotNull2_20",
                errorMsg: "请输入仓库名称"
            },
            {
                name: "brokerId",
                checkType: "isNotNull",
                errorMsg: "请选择仓库经纪人"
            },
            {
                name: "county",
                checkType: "isNotNull",
                errorMsg: "请选择区域"
            },
            {
                name: "address",
                checkType: "isNotNull",
                errorMsg: "请选择仓库地址"
            },
            {
                name: "properties",
                checkType: "isNotNull",
                errorMsg: "请选择仓库性质"
            },
            {
                name: "categoryIds",
                checkType: "isNotNull",
                errorMsg: "请选择仓库品类"
            },
            {
                name: "landArea",
                checkType: "isNotNull",
                errorMsg: "请输入占地面积"
            },
            {
                name: "outdoorsArea",
                checkType: "isNotNull",
                errorMsg: "请输入露天货场面积"
            },
            {
                name: "warehouseArea",
                checkType: "isNotNull",
                errorMsg: "请输入仓库面积"
            },
            {
                name: "warehouseType",
                checkType: "isNotNull",
                errorMsg: "请选择仓库类型"
            },
            // {
            //     name: "iotAbility",
            //     checkType: "isNotNull",
            //     errorMsg: "请选择物联网功能"
            // },
            {
                name: "fireRating",
                checkType: "isNotNull",
                errorMsg: "请选择防火等级"
            },
            {
                name: "propertyStructure",
                checkType: "isNotNull",
                errorMsg: "请选择建筑结构"
            },
            {
                name: "bearing",
                checkType: "isNotNull",
                errorMsg: "请输入地面承重"
            },
            {
                name: "high",
                checkType: "isNotNull",
                errorMsg: "请输入库房高度"
            },
            {
                name: "railwayPlatformWidth",
                checkType: "isNotNull",
                errorMsg: "请输入月台宽度"
            },
            {
                name: "platformHigh",
                checkType: "isNotNull",
                errorMsg: "请输入月台高度"
            },
            {
                name: "deep",
                checkType: "isNotNull",
                errorMsg: "请输入雨棚深度"
            },
            {
                name: "columnSpacing",
                checkType: "isNotNull",
                errorMsg: "是否有升降平台"
            },
            {
                name: "service",
                checkType: "isNotNull",
                errorMsg: "请输入服务功能"
            }
            ],
            wareSelect: [],
            brokerName: "",
            addressList: [],
            cityList: [],
            areasList: [],
            natureList: [{
                name: "私有",
                value: 0
            },
            {
                name: "国有",
                value: 1
            }
            ],
            natureName: "",
            typeSelect: [],
            warehouse_extra: '',
            effectNameSelect: [],
            jiegouName: '',
            jiegouList: [{
                name: '砖混',
                type: 1
            },
            {
                name: '钢混',
                type: 2
            },
            {
                name: '轻钢结构',
                type: 3
            },
            {
                name: '简易库',
                type: 4
            }
            ],
            levelName: '',
            levelList: [{
                name: '甲类',
                type: 1
            },
            {
                name: '乙类',
                type: 2
            },
            {
                name: '乙二类',
                type: 3
            },
            {
                name: '丙一类',
                type: 4
            },
            {
                name: '丙二类',
                type: 5
            },
            {
                name: '丁类',
                type: 6
            },
            {
                name: '戊类',
                type: 7
            }
            ],
            step: 1, //当前步骤
            paramRent: {
                opType: 1, //操作类型 1：添加 2：修改，此时ALIVE_FLAG修改为0就是删除。3：上架下架 4：删除
                warehouseName: '', //仓库名称
                brokerId: '', //仓库经纪人id
                address: '', //仓库地址
                province: '',
                city: '',
                county: '',
                properties: '', //仓库性质
                categoryIds: '', //仓库品类
                landArea: '', //占地面积
                outdoorsArea: '', //露天货场面积
                warehouseArea: '', //仓库面积
                warehouseType: '', //仓库类型
                iotAbility: '', //物联网功能
                railwayIsHave: 0, //是否铁路专线
                railwayCount: '', //铁路条数
                railwayLength: '', //铁路长度
                isCustomer: 0, //是否上线货兑宝
                featureStr: '', //特色标签
                fireRating: '', //防火等级
                propertyStructure: '', //建筑结构
                bearing: '', //地面承重
                high: '', //库房顶高
                railwayPlatformWidth: '', //月台宽度
                platformHigh: '', //月台高度
                deep: '', //雨棚深度
                columnSpacing: '', //升降平台（0无1有）
                service: '', //服务功能
                description: '', //仓库简介
                banners: '', //banner图
                longitude: '', //经度
                latitude: '', //纬度
                cover: '', //封面图
                relationAddress: "", //关联地点
            },
            banner: [],
            selectAll: {}, //初始化选项列表
            addList: [], //关联地点暂存

            addFlag: true,
            // 招聘
            value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
            fuli: [],
            workYearMin: '',
            workYearMax: '',
            classList1: [],
            classList2: [],
            classList: [],
            classify1: '',
            classify2: '',

            nianxianList: [
                {
                    name: "1年",
                    type: 1
                },
                {
                    name: "2年",
                    type: 2
                },
                {
                    name: "3年",
                    type: 3
                },
                {
                    name: "4年",
                    type: 4
                },
                {
                    name: "5年",
                    type: 5
                },
                {
                    name: "6年",
                    type: 6
                },
                {
                    name: "7年",
                    type: 7
                },
                {
                    name: "8年",
                    type: 8
                },
                {
                    name: "9年",
                    type: 9
                },
                {
                    name: "10年",
                    type: 10
                }


            ],
            xueliName: '',

            paramRec: {
                opType: 1,
                title: '', //岗位标题
                classId: '', //类别id
                desc: '', //岗位描述
                postRequire: '',
                companyName: '', //公司名称
                welfare: '', //福利待遇
                salaryMin: '', //薪资最小值
                salaryMax: '', //薪资最大值
                education: '', //学历 枚举或者从tag中取
                workYearMin: '', //工作年限最小值 字符串
                workYearMax: '', //工作年限最大值 字符串
                workTimeBegin: '', //上班时间 开始
                workTimeEnd: '', //下班时间 结束
                provinceId: '', //
                cityId: '', //
                regionId: '', //工作地区
                address: '', //详细地址
                contactName: '', //联系人姓名
                contactWay: '', //联系方式
                isFace: 0, //是否面议
            },
            // 发布求租
            datePlan: [{
                names: 1,
                name: "1年"
            }, {
                names: 2,
                name: "2年"
            }, {
                names: 3,
                name: "3年"
            }, {
                names: 4,
                name: "4年"
            }, {
                names: 5,
                name: "5年"
            }, {
                names: 6,
                name: "6年"
            }, {
                names: 7,
                name: "7年"
            }, {
                names: 8,
                name: "8年"
            }, {
                names: 9,
                name: "9年"
            }, {
                names: 10,
                name: "10年"
            },
            {
                names: 11,
                name: "11年"
            }, {
                names: 12,
                name: "12年"
            }, {
                names: 13,
                name: "13年"
            }, {
                names: 14,
                name: "14年"
            }, {
                names: 15,
                name: "15年"
            }, {
                names: 16,
                name: "16年"
            }, {
                names: 17,
                name: "17年"
            }, {
                names: 18,
                name: "18年"
            }, {
                names: 19,
                name: "19年"
            }, {
                names: 20,
                name: "20年"
            }
            ],
            areaList: [{
                max: 100,
                min: 0,
                name: "100m²以下",
                type: 0
            }, {
                max: 200,
                min: 100,
                name: "100-200m²",
                type: 1
            }, {
                max: 300,
                min: 200,
                name: "200-300m²",
                type: 2
            }, {
                max: 400,
                min: 300,
                name: "300-400m²",
                type: 3
            }, {
                max: 500,
                min: 400,
                name: "400-500m²",
                type: 4
            }, {
                max: 9999,
                min: 500,
                name: "500m²以上",
                type: 5
            }],
            titleArea: '',
            titleAddress: '',
            typeSelect: [],
            useSelect: [],
            jinrongShow: '',
            paramAsk: {
                opType: 1,
                title: '', //求租标题
                areaMax: '', //面积最大值
                areaMin: '', //面积最小值
                provinceId: '', //省id
                cityId: '', //市id
                rentBeginTime: '', //起租日期
                companyName: '', //公司名称
                seekRenterName: '', //求租人姓名
                seekRenterContact: '', //求租人的联系方式
                rentTerm: '', //预计租期
                ypThingType: '', //货物类型 多选 品类
                ypWarehouseUseTo: '', //仓库用途 多选
                ypWarehouseType: '', //仓库的类型 单选
                other: '', //其他内容


            },
            biaoqian: [],
            qiuzumianji: '',
            warehouse_extras: '',

            get_Area: self.get_Area_fn, ssqArea: '',
            props: {
                lazy: true,
                label: 'name', value: 'id',
                lazyLoad: async (node, resolve) => {
                    console.log(node, 9999999999)
                    const { level } = node;
                    if (level >= 1) {
                        if (level == 3) {
                            return
                        }
                        let data = await this.getArea(node.value) //[{ id: 1, name: 222 }]//
                        let res = data.map(i => {
                            i.leaf = level >= 1
                            return i
                        })
                        resolve(res);
                    } else {

                        resolve(this.addressList);
                    }




                }
            },
            propsRent: {
                lazy: true,
                label: 'name', value: 'id',
                lazyLoad: async (node, resolve) => {
                    console.log(node, 9999999999)
                    const { level } = node;
                    if (level >= 1) {
                        if (level == 3) {
                            return
                        }
                        let data = await this.getArea(node.value) //[{ id: 1, name: 222 }]//
                        let res = data.map(i => {
                            i.leaf = level >= 2
                            return i
                        })
                        resolve(res);
                    } else {

                        resolve(this.addressList);
                    }
                }
            },
            propsRec: {
                lazy: true,
                label: 'name', value: 'id',
                lazyLoad: async (node, resolve) => {
                    console.log(node, 9999999999)
                    const { level } = node;
                    if (level >= 1) {
                        if (level == 3) {
                            return
                        }
                        let data = await this.getArea(node.value) //[{ id: 1, name: 222 }]//
                        let res = data.map(i => {
                            i.leaf = level >= 2
                            return i
                        })
                        resolve(res);
                    } else {

                        resolve(this.addressList);
                    }
                }
            },

        };
    },
    mounted() {

        window.addEventListener("scroll", this.fiexdNav);
    },
    destroyed() {
        // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
        window.removeEventListener("scroll", this.fiexdNav);
    },
    async created() {

        this.getSelect()
        this.getAreaSheng()
        this.getAreaShengs()
        this.token = localStorage.getItem("token")
        console.log(this.token, 999999999999999);

        this.get_recruit_class()
        // let data = await this.$api.base.rentDetail({
        //     type: 4,
        //     viewType: 2
        // })
        // console.log(data);
        // this.jinrongShow = data.info
        let user = await this.$api.base.getUser()
        let userinfo = user.info
        if (userinfo == undefined) {
            if (user.code == -1) {
                localStorage.removeItem('token')
                this.$router.push('/')
                setTimeout(() => {
                    location.reload();
                }, 500)
            }
        }
    },

    methods: {
        closeShenhe() {
            // console.log(1111111111111);

            location.reload();
        },
        nextRec() {
            console.log(this.paramRec);
            if (this.paramRec.classId == '') {
                this.$message({
                    message: '请选择岗位类别',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRec.title == '') {
                this.$message({
                    message: '请输入岗位名称',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRec.desc == '') {
                this.$message({
                    message: '请输入工作内容',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRec.postRequire == '') {
                this.$message({
                    message: '请输入岗位要求',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRec.companyName == '') {
                this.$message({
                    message: '请输入公司名称',
                    type: 'warning',
                    offset: 100
                });
                return
            }

            this.step2 = 2
        },
        colseAll() {
            this.cklx1 = false
            this.cklx2 = false
            this.cklx = false
            this.cfpl = false
        },
        handleInput(event) {
            console.log(event);
            // const value = event.target.value
            // console.log(value);
            let data = event * 1
            this.paramRent.outdoorsArea = data.toFixed(2);
            // const reg = /^(\-)*(\d+)\.(\d{0,2}).*$/
            // if (!reg.test(event)) {
            //     this.paramRent.outdoorsArea = event.replace(reg, '$1$2.$3')
            // } else {
            //     this.paramRent.outdoorsArea = ''
            // }
        },
        formatNumber(value) {
            // console.log(value);

            const number = parseFloat(value)

            this.paramRent.outdoorsArea = number.toFixed(2)
            console.log(number.toFixed(2));

        },
        handleChangess(file, fileList) {
            console.log(fileList);
            this.$message({
                message: "最多只能上传5张图片",
                type: 'warning',
                offset: 100
            });

        },
        handleChange1(file, fileList) {
            console.log(file, fileList);
            this.$message({
                message: "只能上传1张图片",
                type: 'warning',
                offset: 100
            });
        },
        changeBQ(e) {
            console.log(e);
            this.paramRent.featureStr = e.join(",")
        },
        jumpLaw(data) {
            // this.dialogVisibleRegister = false
            // this.dialogVisible = false
            // this.dialogVisiblePwd = false
            // this.dialogVisibleEwm = false
            let page = this.$router.resolve({ path: "/law", query: { selectName: data } });
            window.open(page.href, '_blank')
        },
        handleChange(e) {
            console.log(e);
            this.paramRec.classId = e[1]
        },

        getMap(e) {
            console.log(e);
            if (this.mapOne == 1) {
                this.paramRent.latitude = e.lat * 1
                this.paramRent.longitude = e.lng * 1
                this.mapShow = false
            } else if (this.mapOne == 2) {
                this.addGuanLian(e.address, e.lat, e.lng, e.name)
            }
        },

        goAsk() {
            localStorage.setItem("menu", 1)
            // if (this.token == "" || this.token == undefined) {
            //     // this.$message({
            //     //     message: "请登陆后查看",
            //     //     type: 'warning',
            //     //     offset: 100
            //     // });
            //     this.dialogVisible = true
            //     return
            // }
            this.$router.push({
                path: "/askforrent"
            });
        },
        goRec() {
            localStorage.setItem("menu", 1)
            // if (this.token == "" || this.token == undefined) {
            //     // this.$message({
            //     //     message: "请登陆后查看",
            //     //     type: 'warning',
            //     //     offset: 100
            //     // });
            //     this.dialogVisible = true
            //     return
            // }
            this.$router.push({
                path: "/recruitment"
            });
        },
        goJin() {
            localStorage.setItem("menu", 1)
            // if (this.token == "" || this.token == undefined) {
            //     // this.$message({
            //     //     message: "请登陆后查看",
            //     //     type: 'warning',
            //     //     offset: 100
            //     // });
            //     this.dialogVisible = true
            //     return
            // }
            this.$router.push({
                path: "/jinrong"
            });
            // setTimeout(() => {
            //     location.reload();
            // }, 100)
        },
        goXieyi(type) {
            this.dialogVisible = false,
                this.dialogVisiblePwd = false,
                this.dialogVisibleRegister = false,
                this.dialogVisibleEwm = false,
                this.agreeShow = false,
                this.$router.push({
                    path: "/xieyi",
                    query: {
                        type: type
                    },
                });
        },
        // 发布求租
        async fabuAsk() {
            console.log(this.paramAsk);
            // if (this.paramAsk. == '') {
            //     this.$message({
            //         message: '',
            //         type: 'warning',
            //         offset: 100
            //     });
            //     return
            // }
            if (this.paramAsk.areaMax == '') {
                this.$message({
                    message: '请选择求租面积',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramAsk.cityId == '') {
                this.$message({
                    message: '请选择求租地区',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramAsk.rentBeginTime == '') {
                this.$message({
                    message: '请选择起租日期',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramAsk.companyName == '') {
                this.$message({
                    message: '请输入公司名称',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramAsk.seekRenterName == '') {
                this.$message({
                    message: '请输入求租人姓名',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            console.log(this.paramAsk.seekRenterContact);
            if (this.paramAsk.seekRenterContact == '') {
                this.$message({
                    message: '请输入联系方式',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (!/^(1[0-9]{10})$/.test(this.paramAsk.seekRenterContact)) { //电话号码校验
                this.$message({
                    message: '请输入正确的联系方式',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramAsk.rentTerm == '') {
                this.$message({
                    message: '请选择预计租期',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramAsk.ypWarehouseUseTo == '') {
                this.$message({
                    message: '请输入仓库用途',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramAsk.ypWarehouseType == '') {
                this.$message({
                    message: '请选择仓库类型',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramAsk.ypThingType == '') {
                this.$message({
                    message: '请选择存放品类',
                    type: 'warning',
                    offset: 100
                });
                return
            }


            let data = await this.$api.base.askForRent(this.paramAsk)
            console.log(data);
            if (data == null) {
                this.$message({
                    message: '提交成功',
                    type: 'success',
                    offset: 100
                });
                this.fabuEdit3 = false
                nav.classList.remove("fixedNav");
                this.shenhe = true
                this.titleTan = '仓库求租信息发布'
                // this.paramAsk = {
                //     opType: 1,
                //     title: '', //求租标题
                //     areaMax: '', //面积最大值
                //     areaMin: '', //面积最小值
                //     provinceId: '', //省id
                //     cityId: '', //市id
                //     rentBeginTime: '', //起租日期
                //     companyName: '', //公司名称
                //     seekRenterName: '', //求租人姓名
                //     seekRenterContact: '', //求租人的联系方式
                //     rentTerm: '', //预计租期
                //     ypThingType: '', //货物类型 多选 品类
                //     ypWarehouseUseTo: '', //仓库用途 多选
                //     ypWarehouseType: '', //仓库的类型 单选
                //     other: '', //其他内容


                // }

            }
            if (data.code == -9999) {
                this.$message({
                    message: data.msg,
                    type: 'error',
                    offset: 100
                });
            }
        },
        changeUse(e) {
            this.paramAsk.ypWarehouseUseTo = e.join(",")
        },
        changeHouse(e) {
            console.log(e);
            this.paramAsk.ypWarehouseType = e.join(",")
            let arr = []
            for (let i = 0; i < this.selectAll.warehouse_extra.length; i++) {
                for (let j = 0; j < e.length; j++) {
                    if (this.selectAll.warehouse_extra[i].tagId == e[j]) {
                        arr[arr.length] = this.selectAll.warehouse_extra[i].itemName
                    }
                }


            }
            this.typeSelectList1 = arr

        },
        changeCategoryRent(e) {
            console.log(e);
            this.paramRent.categoryIds = e.join(",")
            let arr = []
            for (let i = 0; i < this.selectAll.common_category.length; i++) {
                for (let j = 0; j < e.length; j++) {
                    if (this.selectAll.common_category[i].categoryId == e[j]) {
                        arr[arr.length] = this.selectAll.common_category[i].categoryName
                    }
                }


            }
            console.log(arr);
            this.cateSelectList = arr

        },
        changeCategoryAsk(e) {
            console.log(e);
            this.paramAsk.ypThingType = e.join(",")
            let arr = []
            for (let i = 0; i < this.selectAll.common_category.length; i++) {
                for (let j = 0; j < e.length; j++) {
                    if (this.selectAll.common_category[i].categoryId == e[j]) {
                        arr[arr.length] = this.selectAll.common_category[i].categoryName
                    }
                }


            }
            this.typeSelectList2 = arr
        },
        async changeShengAsk(e) {
            this.paramAsk.provinceId = e
            this.cityList = await this.getArea(e)

        },
        changeAreaMin(e) {
            console.log(this.areaList[e].name);
            this.paramAsk.areaMin = this.areaList[e].min
            this.paramAsk.areaMax = this.areaList[e].max
            console.log(this.addressList);
            this.titleArea = this.areaList[e].name
            if (this.titleArea != '' && this.titleAddress != '') {
                this.paramAsk.title = this.titleAddress + this.titleArea



            }

        },
        async changeShiAsk(e) {
            this.paramAsk.cityId = e
            this.areasList = await this.getArea(e)

        },
        async changeQuAsk(e) {


        },
        // 人才招聘

        // 发布招聘
        async fabuRec() {
            console.log(this.paramRec.isFace);
            if (this.paramRec.isFace == 0) {
                if (this.paramRec.salaryMin == '') {
                    this.$message({
                        message: '请输入最低薪资',
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                if (this.paramRec.salaryMax == '') {
                    this.$message({
                        message: '请输入最高薪资',
                        type: 'warning',
                        offset: 100
                    });
                    return
                }

                if (this.paramRec.salaryMax <= this.paramRec.salaryMin) {
                    this.$message({
                        message: '最高薪资必须大于最低薪资',
                        type: 'warning',
                        offset: 100
                    });
                    return
                }

            }


            if (this.paramRec.education == '') {
                this.$message({
                    message: '请选择学历',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRec.workYearMin == '') {
                this.$message({
                    message: '请选择最小工作年限',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRec.workYearMax == '') {
                this.$message({
                    message: '请选择最大工作年限',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRec.workYearMax <= this.paramRec.workYearMin) {
                this.$message({
                    message: '最大工作年限必须大于最小工作年限',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRec.workTimeBegin == '') {
                this.$message({
                    message: '请选择工作开始时间',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRec.workTimeEnd == '') {
                this.$message({
                    message: '请选择工作结束时间',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRec.regionId == '') {
                this.$message({
                    message: '请选择工作地区',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRec.address == '') {
                this.$message({
                    message: '请输入详细地址',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRec.contactName == '') {
                this.$message({
                    message: '请输入联系人姓名',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRec.contactWay == '') {
                this.$message({
                    message: '请输入联系电话',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRec.contactWay.length != 11) {
                this.$message({
                    message: '联系电话格式不正确',
                    type: 'warning',
                    offset: 100
                });
                return
            }


            let data = await this.$api.base.release_recruit(this.paramRec)
            console.log(data);
            if (data == null) {
                this.$message({
                    message: "提交成功",
                    type: 'success',
                    offset: 100
                });
                this.fabuEdit2 = false
                nav.classList.remove("fixedNav");
                this.shenhe = true
                this.titleTan = '人才招聘信息发布'
            }
            if (data.code == -9999) {
                this.$message({
                    message: data.msg,
                    type: 'error',
                    offset: 100
                });
            }
        },

        changeNianxian1(e) {
            console.log(e)
            this.paramRec.workYearMin = e

        },
        changeNianxian2(e) {
            console.log(e)
            this.paramRec.workYearMax = e
        },
        changeXueli(e) {
            console.log(e)
            this.paramRec.education = e
        },
        changeFuli(e) {
            console.log(e);
            this.paramRec.welfare = e.join(",")
        },
        changeClass(e) {
            console.log(e);
            for (let i = 0; i < this.classList1.length; i++) {
                if (this.classList1[i].id == e) {
                    this.classList2 = this.classList1[i].child
                }
            }
        },
        changeClass1(e) {
            this.paramRec.classId = e
        },
        async get_recruit_class() {
            let data = await this.$api.base.get_recruit_class()
            console.log(data);
            this.classList = data.list
            this.classList1 = data.list
        },
        //表单验证
        validate(param) {
            // console.log(this.$util);
            let validate = new this.$util.Validate();
            this.ruleRent.map(item => {
                let {
                    name,
                } = item
                validate.add(param[name], item);
            })
            let message = validate.start();
            return message;
        },
        submitForm(formName) {
            console.log(this.paramRent);
            let msg = this.validate(this.paramRent);
            if (msg) {
                // uni.$u.toast(msg)
                this.$message({
                    message: msg,
                    type: 'warning',
                    offset: 100
                });
                return;
            }
            this.step = 2
        },
        // 出租发布
        async fabuRent() {

            if (this.paramRent.latitude == '' || this.paramRent.longitude == '') {
                this.$message({
                    message: "请选取仓库经纬度",
                    type: 'warning',
                    offset: 100
                });
                return
            }
            console.log(this.addList);
            let flag = false
            for (var i = 0; i < this.addList.length; i++) {
                if (this.addList[i].type == '') {
                    flag = true
                    break
                }
            }
            if (flag) {
                this.$message({
                    message: "请选择关联地址类别",
                    type: 'warning',
                    offset: 100
                });

                return
            }



            // this.paramRent.banners = JSON.stringify(this.banner)
            console.log(this.paramRent);
            let data = await this.$api.base.rentOut(this.paramRent)
            console.log(data);
            if (data == null) {
                this.$message({
                    message: "提交成功",
                    type: 'success',
                    offset: 100
                });
                this.fabuEdit1 = false
                nav.classList.remove("fixedNav");
                this.shenhe = true
                return
            }
            if (data.code == -9999) {
                this.$message({
                    message: data.msg,
                    type: 'error',
                    offset: 100
                });
            }
            //
        },
        goLx() {
          window.open("https://www.lingxidata.cn/#/")
        },
        clickHandler: async function (e) {
            let lng = e.lnglat.getLng()
            let lat = e.lnglat.getLat()

            console.log(lng, lat);
            // 69d7b3d45c25c1dd6fd1b7072b98fb50
            let url = 'https://restapi.amap.com/v3/geocode/regeo?output=json&location=' + lng + ',' + lat + '&key=69d7b3d45c25c1dd6fd1b7072b98fb50&radius=1000&extensions=all'
            // let url = '/gd/regeo?output=json&location=' + lng + ',' + lat + '&key=69d7b3d45c25c1dd6fd1b7072b98fb50&radius=1000&extensions=all'
            // 在 node.js 用GET请求获取远程图片
            let data = await axios({
                method: 'get',
                url: url,
            })
            console.log(data.data.regeocode);
            let name = data.data.regeocode.addressComponent.streetNumber.street
            let address = data.data.regeocode.formatted_address
            console.log(address);
            if (this.mapOne == 1) {
                this.paramRent.latitude = lat * 1
                this.paramRent.longitude = lng * 1
                this.mapShow = false
            } else if (this.mapOne == 2) {
                this.addGuanLian(address, lat, lng, name)
            }
            //     .then(function (response) {
            //     console.log(response.data.regeocode.formatted_address);
            // });

        },
        addAddress() {
            this.initMap()
            this.mapOne = 2
            this.mapShow = true
        },
        // 关联地点
        async addGuanLian(address, latitude, longitude, name) {
            if (this.paramRent.latitude == '') {

                this.$message({
                    message: "请选取仓库经纬度",
                    type: 'warning',
                    offset: 100
                });
                return
            }
            console.log(address, latitude, longitude);
            let obj = {
                name: '', //名称
                address: '', //地点
                longitude: '', //经纬度
                latitude: '',
                distance: '', //距离
                type: "" //类型
            }
            obj.name = name
            obj.address = address
            obj.latitude = latitude * 1
            obj.longitude = longitude * 1
            obj.distance = this.getDistance(this.paramRent.latitude * 1, this.paramRent.longitude * 1, latitude * 1, longitude * 1)


            this.addList[this.addList.length] = obj

            this.mapShow = false
            this.addFlag = false

            console.log(this.addList);
            setTimeout(() => {
                this.addFlag = true
            }, 10)
            this.paramRent.relationAddress = JSON.stringify(this.addList)

        },
        // 计算经纬度
        getDistance(lat1, lng1, lat2, lng2) {
            // console.log(lat1, lng1, lat2, lng2)
            var radLat1 = lat1 * Math.PI / 180.0;
            var radLat2 = lat2 * Math.PI / 180.0;
            var a = radLat1 - radLat2;
            var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
            var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
                Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
            s = s * 6378.137;
            s = Math.round(s * 10000) / 10000;
            let dis = s.toFixed(2)
            dis = dis * 1
            return dis // 单位千米
        },
        initMap() {
            AMapLoader.load({
                key: "cb69e6ec461b96a3127f98d3089d19cd",             // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: ['AutoComplete', 'Scale', 'ToolBar', 'MouseTool', 'Geocoder', 'Map'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap) => {

                this.map = new AMap.Map("container", {  //设置地图容器id
                    viewMode: "2D",    //是否为3D地图模式
                    zoom: 10,           //初始化地图级别
                    center: [116.397428, 39.90923], //初始化地图中心点位置
                });
                this.map.on('click', this.clickHandler);



            }).catch(e => {
                console.log(e);
            })

        },
        selectLat() {
            this.mapShow = true
            this.mapOne = 1

        },
        async changeSheng(e) {
            this.paramRent.province = e
            this.cityList = await this.getArea(e)

        },
        async changeShi(e) {
            this.paramRent.city = e
            this.areasList = await this.getArea(e)

        },
        async changeQu(e) {
            this.paramRent.county = e


        },
        // 仓库经纪人
        changeBroker(e) {
            console.log(e);
            this.paramRent.brokerId = e
        },
        // 仓库性质
        changeNature(e) {
            console.log(e)
            this.paramRent.properties = e
            // this.natureName = this.natureList[e.detail.value].name
        },
        // 存放品类
        changeCategory(e) {
            console.log(e);
            this.paramRent.categoryIds = e.join(",")


        },
        // 仓库类型
        changeType(e) {
            console.log(e)
            this.paramRent.warehouseType = e.join(",")
            let arr = []
            for (let i = 0; i < this.selectAll.warehouse_extra.length; i++) {
                for (let j = 0; j < e.length; j++) {
                    if (this.selectAll.warehouse_extra[i].tagId == e[j]) {
                        arr[arr.length] = this.selectAll.warehouse_extra[i].itemName
                    }
                }


            }
            this.wareSelectList = arr
            console.log(this.wareSelectList);
        },
        // 物联网功能
        changeEffect(e) {
            console.log(e)
            // this.paramRent.iotAbility = e
            this.paramRent.iotAbility = e.join(",")
        },
        // 是否有铁路专用线
        changeTielu(index) {
            console.log(index);
            if (index == 0) {
                this.tieluDis = true
                this.paramRent.railwayCount = ''
                this.paramRent.railwayLength = ''
            } else if (index == 1) {
                this.tieluDis = false
            }
            console.log(this.paramRent.railwayIsHave);

        },

        // 特色标签选择
        changeLabel(e) {
            console.log(e);
            this.paramRent.featureStr = e
        },

        // 建筑结构
        changeJiegou(e) {
            console.log(e)
            this.paramRent.propertyStructure = e
        },
        // 防火等级
        changeLevel(e) {
            console.log(e)
            this.paramRent.fireRating = e
        },
        async changeShengAsk(e) {
            this.paramAsk.provinceId = e
            this.cityList = await this.getArea(e)

        },
        async changeShiAsk(e) {
            this.paramAsk.cityId = e
            this.areasList = await this.getArea(e)

        },
        async changeQuAsk(e) {


        },

        async changeFn(e) {
            console.log(333, e);
            this.paramAsk.provinceId = e[0]
            this.paramAsk.cityId = e[1]
            let pro = ''
            let city = ''
            let data = await this.getAreas(e[0])
            let cityList = data.list
            for (let i = 0; i < this.addressList.length; i++) {
                if (this.addressList[i].id == e[0]) {
                    pro = this.addressList[i].name

                }

            }
            console.log(data);
            for (let i = 0; i < cityList.length; i++) {
                if (cityList[i].id == e[1]) {
                    city = cityList[i].name
                }

            }
            this.titleAddress = pro + city
            if (this.titleArea != '' && this.titleAddress != '') {
                this.paramAsk.title = this.titleAddress + this.titleArea
            }


        },
        changeFnRent(e) {
            console.log(e);
            this.paramRent.province = e[0]
            this.paramRent.city = e[1]
            this.paramRent.county = e[2]
        },
        // 省
        async changeShengRec(e) {
            this.paramRec.provinceId = e
            this.cityList = await this.getArea(e)

        },
        // 市
        async changeShiRec(e) {
            this.paramRec.cityId = e
            this.areasList = await this.getArea(e)

        },
        // 区
        async changeQuRec(e) {
            this.paramRec.regionId = e


        },
        changeFnRec(e) {
            console.log(e);
            this.paramRec.provinceId = e[0]
            this.paramRec.cityId = e[1]
            this.paramRec.regionId = e[2]
        },
        get_Area_fn(node, resolve) {
            console.log(123, node, resolve);
        },
        // 获取第一层省市区
        async getAreaSheng() {
            let data = await this.$api.base.getArea()
            this.addressList = data.list.map(i => {
                i.value = i.id
                i.label = i.name
                return i
            })
            return this.data

        },


        // 根据父级id获取市区
        async getArea(id) {
            let data = await this.$api.base.getArea({
                areaPid: id
            })
            return data.list.map(i => {
                i.value = i.id
                i.label = i.name
                return i
            })

        },

        // 获取第一层省市区
        async getAreaShengs() {
            let data = await this.$api.base.getArea()
            this.addressList = data.list

        },
        // 根据父级id获取市区
        async getAreas(id) {
            let data = await this.$api.base.getArea({
                areaPid: id
            })
            return data


        },
        //await this.getArea(i.id)
        // 获取初始化选项列表
        async getSelect() {
            let data = await this.$api.base.getSelect()
            console.log(data);
            this.selectAll = data.info

        },

        changeAddressType(e) {
            console.log(e);
            console.log(this.addList);
        },
        changeAddressTypes(e) {
            console.log(e);
        },
        handleEdit(index, row) {
            console.log(index, row);
        },
        handleDelete(index, row) {
            console.log(index, row);
            this.addList.splice(index, 1)
            this.paramRent.relationAddress = JSON.stringify(this.addList)
        },
        handleEdit(index, row) {
            console.log(index, row);

            for (let i = 0; i < this.selectAll.map.length; i++) {
                if (this.selectAll.map[i].tagId == row.type) {
                    this.addList[index].typeName = this.selectAll.map[i].itemName
                }

            }
            console.log(this.addList);
            this.addFlag = false

            console.log(this.addList);
            setTimeout(() => {
                this.addFlag = true
            }, 1)

            this.paramRent.relationAddress = JSON.stringify(this.addList)
        },


        handleRemove(file, fileList) {
            console.log(file, fileList);

        },
        handleRemove1(file, fileList) {
            console.log(file);
            console.log(fileList);

        },
        uploadYulan(response, file, fileList) {
            console.log(response, file, fileList);
            let arr = []
            for (let i = 0; i < fileList.length; i++) {
                let obj = {
                    fileName: fileList[i].response.data.fileName,
                    filePath: fileList[i].response.data.filePath
                }
                arr[arr.length] = obj


            }
            this.paramRent.banners = JSON.stringify(arr)
            this.fileList2 = fileList
        },
        // handleChange() {
        //     // 更新保存图片的数组
        //     this.fileList = this.$refs.draggable.list;
        // },
        uploadFengmian(response, file, fileList) {
            console.log(response, file, fileList);
            let obj = {
                fileName: file.response.data.fileName,
                filePath: file.response.data.filePath
            }
            this.paramRent.cover = JSON.stringify(obj)

        },
        handleBefore(file) {
            console.log(file);
            const isLt1M = file.size / 1024 / 1024 / 5 < 1
            if (!isLt1M) {
                this.$message({
                    message: "上传图片大小不能超过 5M",
                    type: 'error',
                    offset: 100
                });
                return false
            }
            return true

        },
        handlePreview(file) {
            console.log(file);


        },
        tupianNext() {
            if (this.paramRent.cover == '') {
                this.$message({
                    message: "请上传仓库封面图",
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.paramRent.banners == "") {
                this.$message({
                    message: "请上传预览图片",
                    type: 'warning',
                    offset: 100
                });
                return
            }
            this.step = 3
        },

        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },

        onEditorBlur() {
            //失去焦点事件
            // console.log('失去焦点');
        },
        onEditorFocus() {
            //获得焦点事件
            // console.log('获得焦点事件');
        },
        onEditorChange() {
            //内容改变事件
            // console.log('内容改变事件');
            console.log(this.paramRent.description);
        },

        handleCloses() {
            this.mapShow = false
        },
        handleCloseEdit() {
            this.fabuEdit1 = false
            this.fabuEdit2 = false
            this.fabuEdit3 = false
            nav.classList.remove("fixedNav");
        },














        // 获取用户数据
        // async getUser() {
        //     let user = await this.$api.base.getUser()
        //     this.userinfo = user.info
        //     this.paramName.nickName = this.userinfo.nickName
        //     // console.log(this.userinfo);
        // },
        async rentShow(index) {
            localStorage.setItem("menu", 1)
            console.log(index);
            if (this.token == "" || this.token == undefined) {
                // this.$message({
                //     message: "请登陆后发布",
                //     type: 'warning',
                //     offset: 100
                // });
                this.dialogVisible = true
                return
            }
            let user = await this.$api.base.getUser()
            if (index == 1) {
                if (user.info.authStatus != 2) {
                    this.$message({
                        message: "请先进行企业认证",
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                this.fabuEdit1 = true
                nav.classList.add("fixedNav");

                this.fabuEdit2 = false
                this.fabuEdit3 = false
            } else if (index == 2) {
                this.get_recruit_class()
                if (user.info.authStatus != 2) {
                    this.$message({
                        message: "请先进行企业认证",
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                this.fabuEdit1 = false
                this.fabuEdit2 = true
                this.fabuEdit3 = false
                nav.classList.add("fixedNav");
            } else if (index == 3) {

                this.fabuEdit1 = false
                this.fabuEdit2 = false
                this.fabuEdit3 = true
                nav.classList.add("fixedNav");
            }
        },
        async getEwm() {
            let data = await this.$api.base.register(
                {
                    loginType: 2
                }
            )
            this.url = data.authorize_url
            console.log(data);
        },
        async loginByCode() {
            if (this.agreeShow) {
                let param = this.param
                if (param.phone == '') {
                    this.$message({
                        message: "请输入手机号",
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                if (param.phone.length < 11) {
                    this.$message({
                        message: "手机号不能少于11位",
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                if (param.code == '') {
                    this.$message({
                        message: "请输入验证码",
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                param.loginType = 1
                let data = await this.$api.base.login(param)
                console.log(data);
                if (data.code == -9999 || data.code == -1) {
                    this.$message({
                        message: data.msg,
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                localStorage.setItem("token", data.token)
                location.reload();
            } else {
                this.$message({
                    message: "请同意使用条款和隐私声明",
                    type: 'warning',
                    offset: 100
                });
            }

        },
        async registerByCode() {
            if (this.agreeShow) {
                let param = this.param2
                if (param.phone == '') {
                    this.$message({
                        message: "请输入手机号",
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                if (param.phone.length < 11) {
                    this.$message({
                        message: "手机号不能少于11位",
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                if (param.code == '') {
                    this.$message({
                        message: "请输入验证码",
                        type: 'success',
                        offset: 100
                    });
                    return
                }
                param.loginType = 1
                let data = await this.$api.base.register(param)
                console.log(data);
                if (data.code == -9999 || data.code == -1) {
                    this.$message({
                        message: data.msg,
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                localStorage.setItem("token", data.token)
                location.reload();
            } else {
                this.$message({
                    message: "请同意使用条款和隐私声明",
                    type: 'success',
                    offset: 100
                });
            }
        },
        async loginByPwd() {
            if (this.agreeShow) {
                let param = this.param1
                if (param.phone == '') {
                    this.$message({
                        message: "请输入手机号",
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                if (param.phone.length < 11) {
                    this.$message({
                        message: "手机号不能少于11位",
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                if (param.pwd == '') {
                    this.$message({
                        message: "请输入密码",
                        type: 'success',
                        offset: 100
                    });
                    return
                }
                param.loginType = 2
                let data = await this.$api.base.login(param)
                console.log(data);
                if (data.code == -9999 || data.code == -1) {
                    this.$message({
                        message: data.msg,
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                localStorage.setItem("token", data.token)
                location.reload();
            } else {
                this.$message({
                    message: "请同意使用条款和隐私声明",
                    type: 'success',
                    offset: 100
                });
            }
        },
        handleClose() {
            this.agreeShow = false
            this.dialogVisibleRegister = false
            this.dialogVisible = false
            this.dialogVisiblePwd = false
            this.dialogVisibleEwm = false

        },
        loginByEwm() {
            this.getEwm()
            this.agreeShow = false
            this.dialogVisibleRegister = false
            this.dialogVisible = false
            this.dialogVisiblePwd = false
            this.dialogVisibleEwm = true
        },
        goRegister() {
            this.param2.phone = this.param.phone
            clearInterval(this.timer)

            this.dis = false
            this.show = true
            this.isGrey = false

            this.agreeShow = false
            this.dialogVisibleRegister = true
            this.dialogVisible = false
            this.dialogVisiblePwd = false
            this.dialogVisibleEwm = false
        },
        goLoginByPwd() {
            this.param1.phone = this.param.phone
            this.agreeShow = false
            this.dialogVisible = false
            this.dialogVisiblePwd = true
            this.dialogVisibleRegister = false
            this.dialogVisibleEwm = false
        },
        goLoginByCode() {
            this.agreeShow = false
            this.dialogVisible = true
            this.dialogVisiblePwd = false
            this.dialogVisibleRegister = false
            this.dialogVisibleEwm = false
        },
        async getCode(type) {
            let phone = ''
            if (type == "code") {
                if (this.param.phone == "") {
                    this.$message({
                        message: '请输入手机号',
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                phone = this.param.phone
            }
            if (type == "res") {
                if (this.param2.phone == "") {
                    this.$message({
                        message: '请输入手机号',
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                phone = this.param2.phone
            }
            if (phone.length != 11) {
                this.$message({
                    message: '手机号必须是11位',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            let TIME_COUNT = 59;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.isGrey = true;
                this.show = false;
                this.dis = true;


                this.timer = setInterval(() => {
                    if (this.count > 1 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.dis = false;
                        this.isGrey = false;
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);

                let data = await this.$api.base.sendCode({
                    phone
                })
                if (data.code != -9999) {
                    this.$message({
                        message: "发送成功",
                        type: 'success',
                        offset: 100
                    });
                } else {
                    this.$message({
                        message: "发送失败",
                        type: 'error',
                        offset: 100
                    });
                }
            }
        },
        jump(url) {
            localStorage.setItem("menu", 1)
            // location.href = 'https://www.cmstjd.com/'
            window.open(url, "_blank");
        },
        fiexdNav() {
            // if (this.$route.fullPath != "/") {
            //     // document.getElementById('nav').style.background = '#ffffff'
            //     return;
            // }
            // 2.获取当前页面的卷曲高度
            const scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            const nav = document.querySelector("nav");

            // 3.判断卷曲高度是否大于等于导航条的offsetTop值
            if (scrollTop > this.navOffsetTop) {
                //   3.1若满足，则给nav导航添加固定样式
                nav.classList.add("fixedNav");
            } else {
                //   3.2若不满足，则删除nav导航的固定样式
                nav.classList.remove("fixedNav");
            }

            /** 当滚动到一定区域时给导航项添加选中样式 **/
            //  1.获取所有锚点元素
            const contents = document.querySelectorAll(".about-content>div");
            // 2.获取锚点元素的offsetTop值，并收集在一个数组
            const contentsOffsetTop = [];
            contents.forEach((item) => {
                contentsOffsetTop.push(item.offsetTop);
            });
            // 3.获取页面高度
            const pageHeight = window.innerHeight;
            // 4.获取nav的子元素
            const navChildren = document.querySelectorAll(".nav li");
            // 5.遍历锚点元素的offsetTop值
            for (let i = 0; i < contentsOffsetTop.length; i++) {
                // 5.1 设置第一项导航默认为选中状态
                if (i === 0) {
                    navChildren[0].classList.add("active");
                } else if (scrollTop > contentsOffsetTop[i - 1] + pageHeight / 3) {
                    // 排他思想
                    for (let j = 0; j < contentsOffsetTop.length; j++) {
                        navChildren[j].classList.remove("active");
                        navChildren[i].classList.add("active");
                    }
                } else {
                    navChildren[i].classList.remove("active");
                }
            }
        },
        /**
         *设置点击导航跳转到指定选择器对应的锚点元素
         * @param {*} selector
         **/
        skipTo(selector) {
            const navHeight = document.querySelector(".nav").offsetHeight;
            // scrollIntoView() js原生方法，实现锚点滚动过渡
            const target = document.querySelector(selector);
            target.scrollIntoView({ behavior: "smooth" });
            // scrollTo() 把内容滚动到指定的坐标。减去导航高度的目的：导航用定位固定在顶部，如果不减去，导航栏会遮挡一部分内容
            window.scrollTo(0, target.offsetTop - navHeight);
        },
        addClassName(n) {
            localStorage.setItem("menu", 1)
            if (this.$route.fullPath == n) {
                return;
            }
            if (n == "https://www.cmstjd.com/") {
                window.open(n);
                return;
            }
            if (n == "/detail") {
                document.getElementById("nav").classList.add("white");
                document.getElementById("nav").classList.remove("fixedNav");
            } else {
                document.getElementById("nav").classList.remove("white");
            }
            this.$router.push({ path: '/' });
        },
    },
};
</script>
<style lang="less" scoped>

.nav-title {
  font-size: 16px;
}

/deep/ .el-dialog {

    border-radius: 10px !important;
}

/deep/ .el-dialog__title {

    font-weight: 600;
}

.modal {
    display: block;
    position: fixed;
    z-index: 100000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.modal-mask {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.modal-container {
    position: relative;
    z-index: 999999999999999999999999999999999999999;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
}

/deep/ .el-dialog__headerbtn .el-dialog__close:hover {
    color: #909399;
}

// /deep/ .el-dropdown-menu {
//   z-index: 999999999 !important;

// }
.title_other {

    text-align: left;
    width: 195px;
    // height: 150px;
    padding: 10px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    color: #FFFFFF;
    line-height: 20px;
    position: absolute;
    // right: 0;
    top: 40px;
    left: 100px;
    z-index: 99;
}

/deep/ .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: #00B079 !important;
    background-color: #FFF;
}

/deep/ .el-select-dropdown__item.selected {
    color: #00B079;
    font-weight: 700;
}

/deep/ select .el-input.is-focus .el-input__inner {
    border-color: #00B079;
}

.my-dropdown-menu {
    z-index: 99999999999999 !important;
    /* 设置层级的值 */
}

/deep/ .my-dialog .el-dialog__wrapper {
    z-index: 2100 !important;
    /* 设置对话框的z-index值 */
}


/deep/ .el-textarea__inner:focus {
    outline: 0;
    border-color: #00B079;
}

/deep/ .el-tabs__item.is-active {
    color: #00B079;
}

/deep/ .el-tabs__active-bar {

    background-color: #00B079;

}

/deep/ .el-tabs__item:hover {
    color: #00B079;
}

/deep/ .el-tabs__item {
    padding: 0px 35%;

}

/deep/ .el-form-item__label {

    // width: 136px !important;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #00B079;
    background: #00B079;
}

/deep/ .el-radio__input.is-checked+.el-radio__label {
    color: #00B079;
}

/deep/ .el-radio__inner:hover {
    border-color: #00B079;
    // background: #00B079;
    // color: #00B079;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #00B079;
    background: #00B079;
}

/deep/ .el-radio__inner:hover {
    border-color: #00B079;
    // background: #00B079
}

/deep/ .el-radio__input.is-checked+.el-radio__label {
    color: #00B079;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #00B079;
    border-color: #00B079;
}

/deep/ .el-checkbox__inner:hover {
    border-color: #00B079;
}

/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #00B079;
}

.btnQueren {
    text-align: center;
    height: 48px;
    line-height: 48px;
    text-align: center;
    background: #00B079;
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    border: none;
}

.popup {
    /deep/ .el-textarea__inner {
        height: 100px !important;
    }

    .form {
        .stepList {
            .step {
                display: flex;
                justify-content: center;

                img {
                    width: 220px;
                }
            }
        }

        .form_con {
            margin-top: 50px;

            .form_row {
                display: flex;
                // justify-content: space-between;
                // align-items: center;
                // flex-wrap: wrap;
                // height: 42px;
                // margin-bottom: 30px;

                .every_form {
                    // display: flex;
                    // align-items: center;
                    // flex-wrap: wrap;
                    // justify-content: right;
                    width: 50%;


                    .form_inp {
                        width: 70%;
                        cursor: pointer;
                        font-size: 16px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #00B079;

                        span {
                            cursor: pointer;
                            font-size: 16px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #00B079;
                        }


                    }

                    span {
                        margin-left: 10px;
                        font-size: 14px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }


                }


            }
        }
    }
}

/deep/ [class*=" el-icon-"],
[class^=el-icon-] {
    color: #999999 !important;
}

.form {
    padding: 0 60px;
    // background-color: #00B079;

    .ewm_img {
        display: flex;
        justify-content: center;
        margin: 20px 0 0px 0;
        flex-wrap: wrap;

        img {
            width: 176px;
            height: 176px;
        }
    }

    .tip {
        display: flex;
        align-items: center;
        padding-bottom: 30px;
        justify-content: center;
        flex-wrap: wrap;

        .tip_ico {

            margin-right: 10px;

            img {
                width: 36px;
                height: 36px;
            }
        }

        .tip_con {
            .every_con {

                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #CCCCCC;
                line-height: 16px;

            }
        }
    }


    .tel {
        input {
            width: 100%;
            height: 48px;
            background: rgb(240, 240, 240);
            border-radius: 10px 10px 10px 10px;
            padding: 0 20px;
            border: none;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;



        }

        input:focus {
            outline: none;
        }


        margin-bottom: 30px;
    }

    .code {
        margin-bottom: 10px;
        position: relative;

        input {
            width: 100%;
            height: 48px;
            background: rgb(240, 240, 240);
            border-radius: 10px 10px 10px 10px;
            padding: 0 20px;
            border: none;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;

        }

        input:focus {
            outline: none;
        }

        button {
            position: absolute;
            right: -20px;
            top: 12px;
            border: none;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            cursor: pointer;
        }
    }

    .title {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 19px;
        margin-bottom: 50px;
    }

    .agree {

        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .imgs {
            margin-right: 4px;
        }

        img {
            width: 18px;
            height: 18px;
        }

        span {
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #00B079;
            cursor: pointer;
        }
    }

    .login {
        margin: 11px 0;

        button {
            width: 100%;
            height: 48px;
            background: #00B079;
            border-radius: 4px 4px 4px 4px;
            border: none;
            font-size: 20px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            cursor: pointer !important;
        }
    }

    .other {
        display: flex;
        justify-content: space-between;
        padding-bottom: 50px;
        flex-wrap: wrap;

        .register {
            font-size: 18px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #00B079;
            cursor: pointer;
        }

        .loginByPwd {
            font-size: 18px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #00B079;
            cursor: pointer;
        }
    }

    .loginByWx {
        background-color: #00B079;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        padding: 10px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 0 0 10px 10px;

        img {
            width: 24px;
            height: 24px;
        }
    }
}

.popup1 {
    /deep/ .el-input__inner {
        border-color: #DCDFE6 !important;
    }

    /deep/ .el-table__header-wrapper {
        border-radius: 10px !important;

    }

    /deep/ .el-table .cell {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }

    /deep/ .el-table th.el-table__cell {


        background: #F0F0F0 !important;
    }

    /deep/ .el-upload-list--picture .el-upload-list__item-thumbnail {

        width: 200px !important;
        height: 110px !important;

    }

    /deep/ .el-upload-list--picture .el-upload-list__item {

        height: 132px !important;
    }

    /deep/ .el-upload-list--picture .el-upload-list__item.is-success .el-upload-list__item-name {
        line-height: 112px !important;
        margin-top: 0;
    }

}

.popup {
    /deep/ .el-form-item__label {

        font-size: 18px !important;
        font-family: PingFang SC-Regular, PingFang SC !important;
        font-weight: 400 !important;
        color: #333333 !important;
    }

    /deep/ .el-radio__label {
        font-size: 18px !important;
        font-family: PingFang SC-Regular, PingFang SC !important;
        font-weight: 400 !important;
        color: #333333 !important;
    }
}

.popup2 {
    /deep/ .el-radio {

        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    /deep/ .el-input__inner {
        border-color: #DCDFE6 !important;
    }

    /deep/ .el-textarea__inner {

        border-color: #DCDFE6 !important;

    }

    .form_row {
        padding-left: 50px;
        display: flex;
    }

    // /deep/ .el-form-item {
    //     width: 50%;
    // }
}

.popup3 {
    /deep/ .el-radio {

        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    /deep/ .el-input__inner {
        border-color: #DCDFE6 !important;
    }

    /deep/ .el-textarea__inner {

        border-color: #DCDFE6 !important;

    }

    .form_row {
        // padding-left: 50px;
        display: flex;
    }

    // /deep/ .el-form-item {
    //     width: 50%;
    // }
}

.fuwenben {
    /deep/ .el-form-item__content {
        line-height: 22px;
    }

    /deep/ .ql-container {
        min-height: 100px !important;
    }
}

.shenhe {
    .shenheimg {
        text-align: center;

        img {
            width: 200px;
            height: 200px;
        }
    }

    .tishi {
        div {
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 24px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #CCCCCC;
            line-height: 28px;
        }
    }

    .reason {

        width: 60%;
        margin: 0 auto;
        padding: 30px 50px;
        // height: 156px;
        background: #F0F0F0;
        border-radius: 10px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
    }

    .buttn {
        width: 260px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: #00B079;
        border-radius: 10px 10px 10px 10px;
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        margin: 50px auto;
        cursor: pointer;
    }
}

/deep/ .el-textarea__inner {
    // font-size: 14px;
    font-family: auto;
}
</style>