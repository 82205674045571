
// 插件
import utils from '../utils/utils'
import axios from 'axios'

import Nav from './nav/nav.vue'
import Footer from './footer/footer.vue'
import CardS from './Card-s/card-s.vue'
import CardT from './Card-t/card-s.vue'
import MapNav from './map-nav/map-nav.vue'
import NavM from './Nav-m/nav-m.vue'

// 创建axios实例， 配置
const instance = axios.create({
    baseURL: 'https://customs/api/',
    timeout: 1500,
    headers: {'X-Custom-Header': 'customs'}
});

// 自定义组件
const components = {
	Nav:Nav,
	Footer:Footer,
    CardS:CardS,
    CardT:CardT,
    MapNav:MapNav,
    NavM:NavM,
};

const install = function(Vue) {
	if(install.installed) return;

	Object.keys(components).forEach(key => {
		Vue.component(key, components[key]);
	});

    Vue.prototype.$utils = utils

    Vue.prototype.$axios = function(url, method, params = {}) {
        return new Promise((reslove, reject)=>{
            instance({
                method: method ? method : 'get',
                url: url,
                data: params
            }).then(res=>{
                reslove(res)
            }).catch(err=>{
                reject(err)
            });
        })
    }
}

export default install