// import * as util from './util'
import Validate from './validate.js';
export default {
	Validate,
	log: console.log,
	// log: () => {},
	/**
	 * 跳转到指定页面url
	 * 支持tabBar页面
	 * @param {string}  url
	 * @param {object}  query
	 */

	//格式化时间
	formatTime(date, format) {
		let newFormat = format || 'YY-M-D h:m:s';
		let formatNumber = this.formatNumber;
		let newDate = date || new Date();
		if (Object.prototype.toString.call(newDate).slice(8, -1) !== "Date") {
			newDate = new Date(date);
		}
		let week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', '日', '一', '二', '三',
			'四',
			'五', '六'
		];
		return newFormat.replace(/YY|Y|M|D|h|m|s|week|星期/g, function (a) {
			switch (a) {
				case 'YY':
					return newDate.getFullYear();
				case 'Y':
					return (newDate.getFullYear() + '').slice(2);
				case 'M':
					return formatNumber(newDate.getMonth() + 1);
				case 'D':
					return formatNumber(newDate.getDate());
				case 'h':
					return formatNumber(newDate.getHours());
				case 'm':
					return formatNumber(newDate.getMinutes());
				case 's':
					return formatNumber(newDate.getSeconds());
				case '星期':
					return "星期" + week[newDate.getDay() + 7];
				case 'week':
					return week[newDate.getDay()];
			}
		})
	},
	// 日期转时间戳
	DateToUnix: function (string) {
		var f = string.split(' ', 2);
		var d = (f[0] ? f[0] : '').split('-', 3);
		var t = (f[1] ? f[1] : '').split(':', 3);
		return (new Date(
			parseInt(d[0], 10) || null,
			(parseInt(d[1], 10) || 1) - 1,
			parseInt(d[2], 10) || null,
			parseInt(t[0], 10) || null,
			parseInt(t[1], 10) || null,
			parseInt(t[2], 10) || null
		)).getTime() / 1000;
	},
	//格式化数字
	formatNumber(n) {
		n = n.toString();
		return n[1] ? n : '0' + n
	},
	// 保留小数 （不四舍五入）
	formatDecimal(num, decimal) {
		num = num.toString()
		let index = num.indexOf('.')
		if (index !== -1) {
			num = num.substring(0, decimal + index + 1)
		} else {
			num = num.substring(0)
		}
		return parseFloat(num).toFixed(decimal)
	},









}