// api_config.js    http.js   配置axios
// 引入axios
import axios from "axios";
import QS from 'qs';
import router from '@/router'
import {
    data
} from "jquery";

// 设置默认前缀
axios.defaults.baseURL = process.env.VUE_APP_BASE_API + "/system";
// axios.defaults.baseURL = "http://mnykjgzs.gnway.cc/system";
axios.defaults.headers['Content-Type'] = 'application/json';
// 请求拦截器
axios.interceptors.request.use(config => {
    // config.headers['token'] = window.localStorage.getItem('auth')
    config.headers['token'] = localStorage.getItem("token") || ''
    return config;
})
// 响应拦截器
axios.interceptors.response.use(config => {
    if (config.data.code > '300') {
        router.replace({
            name: "error",
            params: {
                message: config.data
            }
        })
    } else {
        return config;
    }
})


// 封装get请求
export default {
    get(url, params) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params
            }).then(res => {

                let {
                    code,
                    data,
                    msg
                } = res.data
                code = code * 1

                if (code === 200) {
                    resolve(data)
                    return
                };
                if (code === -9999) {
                    resolve(res.data);

                }
                if (code === -1) {
                    console.log(1111);

                    resolve(res.data);
                }
            }).catch(err => {
                reject(err.data);
            })
        });
    },
    // 封装post请求
    post(url, params) {
      
        return new Promise((resolve, reject) => {
            axios.post(url, params).then(res => {

              const whiteList = [
                "/app/check_group_user",
                '/app/warehouseGroupList'
              ]

              const isExist = whiteList.includes(url);

                let {
                    code,
                    data,
                    msg
                } = res.data
                code = code * 1
                if (code === 200) {
                  isExist ? resolve(res.data) : resolve(data)
                    // resolve(data)
                    return
                };
                if (code === -9999) {
                    // console.log(code);
                  isExist ? resolve(res.data) : resolve(data)
                  
                    // uni.$u.toast(msg)
                    // resolve(res.data);
                    // return code

                }
                if (code === -1) {
                  
                  isExist ? resolve(res.data) : resolve(data)
                  // this.$alert('当前登录状态已失效，请重新登录', '提示', {
                  //   confirmButtonText: '确定',
                  //   callback: action => {
                  //     localStorage.removeItem('token')
                  //     this.$router.push('/')
                  //     setTimeout(() => {
                  //         location.reload();
                  //     }, 1000)
                  //   }
                  // });
                    // localStorage.removeItem('token')
                    // this.$router.push('/')
                    // setTimeout(() => {
                    //     location.reload();
                    // }, 1000)
                    // resolve(res.data);
                }
            }).catch(err => {
                reject(err.data);
            })
        });
    }

}
// export const get = (url, params) => {
//     return new Promise((resolve, reject) => {
//         axios.get(url, {
//             params
//         }).then(res => {
//             resolve(res.data);
//         }).catch(err => {
//             reject(err.data);
//         })
//     });
// }

// // 封装post请求
// export const post = (url, params) => {
//     return new Promise((resolve, reject) => {
//         axios.post(url, QS.stringify(params)).then(res => {
//             resolve(res.data);
//         }).catch(err => {
//             reject(err.data);
//         })
//     });
// }